import { NgModule } from '@angular/core';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzFlexModule, NzAlign, NzJustify } from 'ng-zorro-antd/flex';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzMessageModule } from 'ng-zorro-antd/message';

@NgModule({
  declarations: [],
  imports: [
    NzProgressModule,
    NzSpinModule,
    NzFlexModule,
    NzCardModule,
    NzGridModule,
    NzDividerModule,
    NzImageModule,
    NzButtonModule,
    NzInputModule,
    NzIconModule,
    NzDropDownModule,
    NzTableModule,
    NzModalModule,
    NzCollapseModule,
    NzAvatarModule,
    NzToolTipModule,
    NzUploadModule,
    NzPopoverModule,
    NzEmptyModule,
    NzCheckboxModule,
    NzMessageModule,
  ],
  exports: [
    NzProgressModule,
    NzSpinModule,
    NzCardModule,
    NzGridModule,
    NzDividerModule,
    NzImageModule,
    NzButtonModule,
    NzInputModule,
    NzIconModule,
    NzDropDownModule,
    NzTableModule,
    NzModalModule,
    NzCollapseModule,
    NzAvatarModule,
    NzToolTipModule,
    NzUploadModule,
    NzPopoverModule,
    NzEmptyModule,
    NzCheckboxModule,
    NzMessageModule,
  ],
})
export class AntDModule {}
