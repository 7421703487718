import {Injectable} from "@angular/core";
import {BaseService} from "../base.service";
import { CANCEL_TICKET, CHAT_TICKET, COUNT_TICKET, COUNT_UNREAD, CREATE_TICKET, EXPEDITION_LIST, LIST_AWB, LIST_TICKET, TYPE_TICKET } from "src/app/constants/endpoint.const";
import { CreateTicketModel } from "src/app/models/create-ticket.model";
import { listTicketModel } from "src/app/models/list-ticket.model";

@Injectable({
    providedIn: 'root',
  })

  export class TicketService {
    constructor(private basService: BaseService) {
    }

    listTicket(params: listTicketModel) {
        return this.basService.getData(LIST_TICKET, params, null);
      }
    detailTicket(id: string) {
        return this.basService.getData(LIST_TICKET + `/${id}`, null, null);
      }
    countTicket() {
        return this.basService.getData(COUNT_TICKET, null, null);
      }
    countUnread() {
        return this.basService.getData(COUNT_UNREAD, null, null);
      }
    createTicket(params: any) {
        return this.basService.postData(CREATE_TICKET, params, null);
      }
    typeTicket(params: any) {
        return this.basService.getData(TYPE_TICKET, params, null);
      }
    listAwb(id: string) {
        return this.basService.getData(LIST_AWB + `/${id}`, null, null);
      }
    chatTicket(id: string) {
        return this.basService.getData(CHAT_TICKET + `/${id}`, null, null);
      }
    sendMessages(id: string, params: any) {
        return this.basService.postData(CHAT_TICKET + `/${id}`, params, null);
      }
    cancelTicket(id: string) {
        return this.basService.putData(CANCEL_TICKET + `/${id}`, null, null);
      }
    expeditionList() {
      return this.basService.getData(EXPEDITION_LIST, null, null);
    }
}