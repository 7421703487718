<div class="d-flex content flex-column wrapper-main">
  <app-navbar
    [active]="active"
    (collapseToogle)="collapseToogle($event)"
  ></app-navbar>
  <div style="margin-top: 60px; overflow: hidden" class="d-flex flex-row">
    <div
      class="layer"
      [ngStyle]="
        active
          ? { visibility: 'visible', transition: 'all .3s ease-in-out' }
          : {
              visibility: 'hidden',
              transition: 'all .3s ease-in-out',
              background: 'none'
            }
      "
    >
      <nav
        id="sidebar"
        class="sidebar flex-fill"
        [ngClass]="{ 'sidebar-active': !active }"
      >
        <app-sidebar [active]="active"></app-sidebar>
      </nav>
    </div>
    <div id="content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
