import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';
import { Subject, interval, takeUntil, filter } from 'rxjs';
import { REFRESH_TOKEN } from 'src/app/constants/endpoint.const';
import { BaseService } from 'src/app/services/base.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  active: boolean = true;
  private tokenCheckInterval: any;
  private destroy$ = new Subject<void>();

  constructor(
    public baseService: BaseService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  collapseToogle(e: boolean): void {
    this.active = e;
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target: EventTarget): void {
    if ((target as HTMLElement).className === 'layer') {
      this.active = false;
    }
  }

  isActive(e: boolean): void {
    this.active = e;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
