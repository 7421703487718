import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import {
  CHECK_PIN,
  LOGIN,
  PROFILE,
  REGISTER,
  UPDATE_PIN,
} from '../../constants/endpoint.const';
import { UpdatePinTypes } from '../../types/request/update-pin-types';
import { LoginRequestType } from '../../types/request/login-request.type';
import { RegisterRequestType } from 'src/app/types/request/register-request.type';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private baseService: BaseService) {}

  login(body: LoginRequestType) {
    return this.baseService.postData(LOGIN, body, null, null);
  }
  register(body: RegisterRequestType) {
    return this.baseService.postData(REGISTER, body, null, null);
  }

  profile() {
    return this.baseService.getData(PROFILE, null, null);
  }

  checkPin(params: UpdatePinTypes) {
    return this.baseService.getData(CHECK_PIN, params, null);
  }

  updatePin(body: UpdatePinTypes) {
    return this.baseService.putData(UPDATE_PIN, body, null, null);
  }
}
