import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthLayoutComponent } from './auth-layout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AntDModule } from 'src/app/modules/shared/antd.module';

@NgModule({
  declarations: [AuthLayoutComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, AntDModule],
})
export class AuthLayoutModule {}
