<nav id="sidebar" class="sidebar position-fixed card pe-3 bg-white h-100 mt-4">
  <div class="card-body d-flex flex-column" style="position: relative">
    <div
      [ngClass]="active ? 'card-body' : 'card-body p-3'"
      class="d-flex flex-column justify-content-start flex-grow-0"
    >
      <div
        class=""
        *ngIf="
          profileData.is_rajaongkir === false || profileData.is_openapi === true
        "
      >
        <a
          routerLink="/pages/dashboard"
          class="nav-link navItemClass"
          routerLinkActive="active-link"
        >
          <div
            [ngClass]="
              activeButton === 'dashboard'
                ? 'd-flex flex-row nav justify-content-start ps-3 pe-3 active'
                : 'd-flex flex-row nav justify-content-start ps-3 pe-3'
            "
            (click)="activeButton = 'dashboard'"
          >
            <img
              class="non"
              [ngClass]="active ? 'me-2' : 'me-0'"
              [src]="
                activeButton === 'dashboard'
                  ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/home-hashtag.svg'
                  : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/home-hashtag.svg'
              "
              alt=""
            />
            <img
              class="hov me-2"
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/home-hashtag.svg"
              alt=""
            />
            <span *ngIf="active" class="link">Dashboard</span>
          </div>
        </a>
      </div>
      <div
        class=""
        *ngIf="
          profileData.is_rajaongkir === false || profileData.is_openapi === true
        "
      >
        <a
          routerLink="/pages/data-order"
          class="nav-link navItemClass"
          routerLinkActive="active-link"
        >
          <div
            [ngClass]="
              activeButton === 'Data Order'
                ? 'd-flex flex-row nav justify-content-start ps-3 pe-3 active'
                : 'd-flex flex-row nav justify-content-start ps-3 pe-3'
            "
            (click)="activeButton = 'Data Order'"
          >
            <img
              class="non"
              [ngClass]="active ? 'me-2' : 'me-0'"
              [src]="
                activeButton === 'Data Order'
                  ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/bag.svg'
                  : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/bag.svg'
              "
              alt=""
            />
            <img
              class="hov me-2"
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/bag.svg"
              alt=""
            />
            <span *ngIf="active" class="link">Data Order</span>
          </div>
        </a>
      </div>
      <div
        class=""
        *ngIf="
          profileData.is_rajaongkir === false || profileData.is_openapi === true
        "
      >
        <a
          routerLink="/pages/keuangan/saldo"
          class="nav-link navItemClass"
          routerLinkActive="active-link"
        >
          <div
            [ngClass]="
              activeButton === 'Keuangan'
                ? 'd-flex flex-row nav justify-content-start ps-3 pe-3 active'
                : 'd-flex flex-row nav justify-content-start ps-3 pe-3'
            "
            (click)="activeButton = 'Keuangan'"
          >
            <img
              class="non"
              [ngClass]="active ? 'me-2' : 'me-0'"
              [src]="
                activeButton === 'Keuangan'
                  ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/empty-wallet.svg'
                  : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/empty-wallet.svg'
              "
              alt=""
            />
            <img
              class="hov me-2"
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/empty-wallet.svg"
              alt=""
            />
            <span *ngIf="active" class="link">Keuangan</span>
          </div>
        </a>
      </div>
      <div
        class=""
        *ngIf="
          profileData.is_rajaongkir === true || profileData.is_openapi === false
        "
      >
        <a
          routerLink="/pages/billings"
          class="nav-link navItemClass"
          routerLinkActive="active-link"
        >
          <div
            [ngClass]="
              activeButton === 'Billing'
                ? 'd-flex flex-row nav justify-content-start ps-3 pe-3 active'
                : 'd-flex flex-row nav justify-content-start ps-3 pe-3'
            "
            (click)="activeButton = 'Billing'"
          >
            <img
              class="non"
              [ngClass]="active ? 'me-2' : 'me-0'"
              [src]="
                activeButton === 'Billing'
                  ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/empty-wallet.svg'
                  : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/empty-wallet.svg'
              "
              alt=""
            />
            <img
              class="hov me-2"
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/empty-wallet.svg"
              alt=""
            />
            <span *ngIf="active" class="link">Billing</span>
          </div>
        </a>
      </div>
      <div>
        <div
          (click)="isOpen = !isOpen"
          [attr.aria-expanded]="!isOpen"
          aria-controls="collapseExample"
        >
          <div
            [ngClass]="
              activeButton === 'Pengaturan'
                ? ' collapsed d-flex flex-row nav justify-content-start ps-3 pe-3 active'
                : ' collapsed d-flex flex-row nav justify-content-start ps-3 pe-3'
            "
            data-bs-toggle="collapse"
            aria-expanded="false"
            (click)="activeButton = 'Pengaturan'"
          >
            <img
              class="non"
              [ngClass]="active ? 'me-2' : 'me-0'"
              [src]="
                activeButton === 'Pengaturan'
                  ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/setting-2.svg'
                  : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/setting-2.svg'
              "
              alt=""
            />
            <img
              class="hov me-2"
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/setting-2.svg"
              alt=""
            />
            <span class="link">Pengaturan</span>
            <img
              class="ms-2 non"
              [src]="
                isOpen
                  ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg'
                  : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-up-2.svg'
              "
              alt=""
            />
            <img
              class="ms-2 hov"
              [src]="
                activeButton === 'Pengaturan' || isOpen
                  ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/arrow-down-1.svg'
                  : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/arrow-up-2.svg'
              "
              alt=""
            />
          </div>
        </div>
      </div>
      <div #collapse="ngbCollapse" [(ngbCollapse)]="isOpen">
        <a
          routerLink="/pages/pengaturan/profile"
          class="accordion accordion-flush nav-link navItemClass"
          routerLinkActive="active-link"
        >
          <div
            [ngClass]="
              activeButton === 'Profile'
                ? ' collapsed d-flex flex-row nav justify-content-start ps-3 pe-3 active'
                : ' collapsed d-flex flex-row nav justify-content-start ps-3 pe-3'
            "
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
            (click)="activeButton = 'Profile'"
          >
            <span class="link pe-2">Profile</span>
          </div>
        </a>
        <a
          routerLink="/pages/pengaturan/pin"
          class="accordion accordion-flush nav-link navItemClass"
          routerLinkActive="active-link"
        >
          <div
            [ngClass]="
              activeButton === 'PIN'
                ? ' collapsed d-flex flex-row nav justify-content-start ps-3 pe-3 active'
                : ' collapsed d-flex flex-row nav justify-content-start ps-3 pe-3'
            "
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
            (click)="activeButton = 'PIN'"
          >
            <span class="link pe-2">PIN</span>
          </div>
        </a>
        <a
          routerLink="/pages/pengaturan/rekening"
          class="accordion accordion-flush nav-link navItemClass"
          routerLinkActive="active-link"
        >
          <div
            [ngClass]="
              activeButton === 'Rekening Bank'
                ? ' collapsed d-flex flex-row nav justify-content-start ps-3 pe-3 active'
                : ' collapsed d-flex flex-row nav justify-content-start ps-3 pe-3'
            "
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
            (click)="activeButton = 'Rekening Bank'"
          >
            <span class="link pe-2">Rekening Bank</span>
          </div>
        </a>
      </div>
      <div
        class=""
        *ngIf="
          profileData.is_rajaongkir === false || profileData.is_openapi === true
        "
      >
        <a
          routerLink="/pages/ticketing"
          class="nav-link navItemClass"
          routerLinkActive="active-link"
        >
          <div
            [ngClass]="
              activeButton === 'Kendala'
                ? 'd-flex flex-row nav justify-content-start ps-3 pe-3 active'
                : 'd-flex flex-row nav justify-content-start ps-3 pe-3'
            "
            (click)="activeButton = 'Kendala'"
          >
            <img
              class="non"
              [ngClass]="active ? 'me-2' : 'me-0'"
              [src]="
                activeButton === 'Kendala'
                  ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/message-remove.svg'
                  : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/message-remove.svg'
              "
              alt=""
            />
            <img
              class="hov me-2"
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/message-remove.svg"
              alt=""
            />
            <span *ngIf="active" class="link">Kendala</span>
          </div>
        </a>
      </div>
    </div>
    <div
      class="d-flex flex-column justify-content-end flex-grow-1"
      style="margin-bottom: 100px"
      *ngIf="
        profileData.is_rajaongkir === false || profileData.is_openapi === true
      "
    >
      <a
        style="text-decoration: none"
        href="https://developer.komship.id/"
        target="_blank"
      >
        <div class="d-flex flex-row gap-2 align-items-center">
          <img src="assets/icons/black/api-doc.svg" alt="" />
          <div>API Docs</div>
        </div>
      </a>
    </div>
  </div>
</nav>
