<div class="login-container">
  <img
    src="https://storage.googleapis.com/komerce/assets/illustration/bg-yellow-ro.png"
    class="background"
  />
  <div class="content-login">
    <div
      class="w-100 h-100 gap-3 d-flex align-items-center justify-content-center"
    >
      <div
        class="d-flex flex-column gap-3 col-lg-6 col-xl-5 col-12 col-md-6 justify-content-center text-center"
      >
        <div class="logo-login">
          <img
            style="max-height: 50px"
            src="https://storage.googleapis.com/komerce/core/RO%20by%20Komerce.svg"
          />
        </div>
        <div>
          <div
            class="px-md-5 px-3 py-5 m-3 card shadow d-flex flex-column gap-3 justify-content-center text-center align-items-center"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/elements/Checkbox-on.svg"
              alt="check"
              class="mt-3"
              style="max-width: 50px; max-height: 50px"
            />
            <h5><strong>Verifikasi Berhasil</strong></h5>
            <span style="color: #828282"
              >Selamat <span class="text-yellow">{{ email }}</span> layanan
              RajaOngkir telah aktif</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
