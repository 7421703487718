import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketingComponent } from './ticketing.component';
import { NzCardModule } from 'ng-zorro-antd/card';
import { ModalCreateTicketComponent } from './components/modal-create-ticket/modal-create-ticket.component';

@NgModule({
  declarations: [
    
  ],
  imports: [
    CommonModule,
    NzCardModule
  ],
  exports: [
  ]
})
export class TicketingModule { }