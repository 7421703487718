import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/api/user.service';
import { PinModel } from 'src/app/models/pin.model';
import { ToastService } from 'src/app/services/toast.service';
import { BalanceService } from 'src/app/services/api/balance.service';

@Component({
  selector: 'app-modal-pin-wd',
  templateUrl: './modal-pin-verification.component.html',
  styleUrls: ['./modal-pin-verification.component.scss'],
})
export class ModalPinWDComponent implements OnInit {
  paramsWD: any
  pin!: PinModel
  settings: any = {
    length: 6,
    numbersOnly: true,
  };
  @Input() otpParams: any = {
    pin: '',
  };
  otp: string = '';
  isErrorMessage: boolean = false;
  destroy$ = new Subject();
  isDisabled: boolean = true;
  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private userService: UserService,
    private router: Router,
    private toastService: ToastService,
    private balanceService: BalanceService,
  ) {}
  ngOnInit(): void {
  }
  handleClose(e: any): void {
    this.activeModal.dismiss();
  }
  selectedItem(e: any) {}

  onChange(e: string) {
    this.otp = e;
    if (e.length === 6) {
      this.isDisabled = false;
    }
  }
  handleConfirm(e:any) {
    this.otpParams.pin = this.otp
    this.userService
      .checkPin(this.otpParams)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.data.is_match === true) {
          this.handleWD()
          this.activeModal.dismiss();
      } else {
        this.toastService.show(null, 'PIN yang Kamu masukkan salah', 'danger', {
          classname: 'bg-danger',
          delay: 3000,
        });
        this.isErrorMessage = true
      }
      })
  }

  handleWD() {
      this.balanceService
        .request(this.paramsWD)
        .pipe(takeUntil(this.destroy$))
          .subscribe(
            (res) => {
              if (res.meta.code === 201) {
                this.toastService.show(null, 'Berhasil mengajukan penarikan saldo', 'success', {
                  classname: 'bg-success',
                  delay: 5000,
                });
                this.activeModal.dismiss()
              }
            },
              (error) => {}
      );
  }
  onKeyDown(event: KeyboardEvent) {
    if (event.code === 'Backspace') {
      this.isErrorMessage = false;
      this.isDisabled = true;
    }
  }

}
