import {NavigationType} from "../types/navigation.type";

export const navigation: NavigationType[] = [
  {
      id: 'dashboard',
      name: 'Dashboard',
      icon: 'icon-home-hashtag',
      url: '/pages/dashboard'
  },
  {
    id: 'data-order',
    name: 'Data Order',
    icon: 'icon-bag',
    url: '/pages/data-order',
  },
  {
    id: 'keuangan',
    icon: 'icon-wallet-3',
    url: 'keuangan',
    name: 'Keuangan',
  },
  {
    id: 'pengaturan',
    icon: 'icon-setting-2',
    url: 'pengaturan',
    name: 'Pengaturan',
    children: [
      {
        id: 'profile',
        name: 'Profile',
        icon: 'dashboard',
        url: '/pages/pengaturan/profile'
      },
      {
        id: 'pin',
        name: 'Pin',
        icon: 'dashboard',
        url: '/pages/pengaturan/pin'
      },
      {
        id: 'pengaturan-bank',
        name: 'Bank',
        icon: 'dashboard',
        url: '/pages/pengaturan/bank'
      },
    ]
  },
  {
    id: 'kendala',
    icon: 'icon-message-tandaseru',
    url: 'kendala',
    name: 'Kendala',
  }

]
