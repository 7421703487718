import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { OperatorFunction, Observable, Subject, merge } from 'rxjs';
import { NgbActiveModal, NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { BankModel } from 'src/app/models/bank.model';
import { BankService } from 'src/app/services/api/bank.service';
import { BalanceService } from 'src/app/services/api/balance.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/api/user.service';
import { PinModel } from 'src/app/models/pin.model';
import { ResponseCustomType } from 'src/app/types/response/response.type';
import { BalanceResponseType } from 'src/app/types/response/balance-response.type';
import {navigation} from "../../../../constants/navigation.const";
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-modal-tarik-kom',
  templateUrl: './modal-tarik-kom.component.html',
  styleUrls: ['./modal-tarik-kom.component.scss']
})

export class ModalTarikKomisiComponent implements OnInit {
  states: BankModel[] = [];
  bank!: BankModel
  @Input() pin!: PinModel
  accountNumber!: number;
  destroy$ = new Subject();
  formGroup!: FormGroup;
  nominal!: number;
  textAlertMin: string = '';
  isDisabled: boolean = true;
  isErrorMessage: boolean = false;
  otp: string = '000000';
  settings: any = {
    length: 6,
    numbersOnly: true,
  };
  @Output() filterResult = new EventEmitter();
  @Input() paramsWD: any = {
    bank_account_id: '',
    nominal: '',
    type: 'commission'
  }
  @Input() otpParams: any = {
    pin: '',
  };
  @ViewChild('instance', { static: true })
  instance!: NgbTypeahead;
	focus$ = new Subject<string>();
	click$ = new Subject<string>();
  balance: BalanceResponseType = {
    balance: 0,
    commission: 0,
  };
  navigation= navigation

    constructor(
        private activeModal: NgbActiveModal,
        private bankService: BankService,
        private modalService: NgbModal,
        private userService: UserService,
        private toastService: ToastService,
        private balanceService: BalanceService,
        private route: Router
        ) { }
    ngOnInit(): void {
      this.getBankList()
      this.getBalance()
    }

    getBankList(): void {
      this.bankService
        .bankList()
        .pipe(takeUntil(this.destroy$))
          .subscribe(
            (res) => {
                this.states = res.data
                this.bank = res.data[0]
                this.paramsWD.bank_account_id = res.data[0].id
                this.filterResult.emit(this.paramsWD)
            },
              (error) => {}
      );
    }

    inputBank(event: any): void {
      const selectedId = event.target.value;

      this.paramsWD.bank_account_id = parseInt(selectedId, 10)
      this.filterResult.emit(this.paramsWD)
    }

    selectedItem(e: any){
      this.bank = e.item
    }

    formatter = (x: { bank_name: string, bank_code: string, bank_owner: string, bank_number: number }) =>
    x.bank_code + ' - ' + x.bank_number + ' - ' + x.bank_owner;

    search: OperatorFunction<
      string,
      readonly { bank_name: string }[]
    > = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
      const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
      const inputFocus$ = this.focus$;

      return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$, text$).pipe(
        debounceTime(500),
        map((term) =>
          term === ''
            ? []
            : this.states
                .filter(
                  (v: BankModel) =>
                    (v.bank_name)
                      .toLowerCase()
                      .indexOf(term.toLowerCase()) > -1
                )
                .slice(0, 10)
        )
      );
    }

    handleClose(e: any): void{
        this.activeModal.dismiss()
        this.textAlertMin = ''
    }

    handleConfirm(e:any){
      this.activeModal.dismiss()
      this.textAlertMin = ''
      this.modalService.open(e, {
        backdrop: 'static',
        centered: true,
      });
    }
    close(value: any) {
      this.modalService.dismissAll(value);
      this.modalService.open(ModalTarikKomisiComponent, {
        backdrop: 'static',
        centered: true,
      });
      this.isErrorMessage = false;
    }

    onKeyDown(event: KeyboardEvent) {
      if (event.code === 'Backspace') {
        this.isErrorMessage = false;
        this.isDisabled = true;
      }
    }
  
    onChange(e: string) {
      this.otp = e;
      if (e.length === 6) {
        this.isDisabled = false;
      }
    }

    handleVerif(value: any) {
      
      this.otpParams.pin = this.otp
      this.userService
        .checkPin(this.otpParams)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res.data.is_match === true) {
          this.pin = res.data
          this.handleWD(value)
        } else {
          this.isErrorMessage = true
        }
        })
    }

    handleWD(value: any) {
      this.balanceService
        .request(this.paramsWD)
        .pipe(takeUntil(this.destroy$))
          .subscribe(
            (res) => {
              if (res.meta.code === 201) {
                this.toastService.show(null, 'Berhasil mengajukan penarikan komisi', 'success', {
                  classname: 'bg-success',
                  delay: 5000,
                });
                this.modalService.dismissAll(value);
                const currentRoute = this.route.url;
                if (currentRoute.endsWith('/pages/keuangan/komisi')) {
                  window.location.reload();
                }
              }
            },
              (error) => {}
      );
  }

  handleNominal(value: any) {
    if (value === '') {
      this.isDisabled = true;
      this.textAlertMin = ''
    } else {
      this.isDisabled = false;
      this.textAlertMin = ''
    }
    
    const input = <HTMLInputElement>document.getElementById('nominal-input');
    let inputVal = (<HTMLInputElement>document.getElementById('nominal-input'))
      .value;

    if (inputVal === '' || inputVal === 'Rp' || inputVal === 'Rp ') {
      return;
    }

    const originalLen = inputVal.length;
    let caretPos = input.selectionStart;

    if (inputVal.indexOf(',') >= 0) {
      const decimalPos = inputVal.indexOf(',');
      let leftSide = inputVal.substring(0, decimalPos);
      let rightSide = inputVal.substring(decimalPos);

      leftSide = this.formatNumber(leftSide);
      rightSide = this.formatNumber(rightSide);

      rightSide = rightSide.substring(0, 2);
      inputVal = leftSide + ',' + rightSide; // eslint-disable-line
    } else {
      inputVal = inputVal.replace(/\./g, '');
      inputVal = this.formatNumber(inputVal);
    }

    input.value = inputVal;

    const updatedLen = inputVal.length;
    caretPos = updatedLen - originalLen + caretPos!;
    input.setSelectionRange(caretPos, caretPos);

    const valEnd = inputVal.replace(/\./g, '')

    if (parseInt(valEnd, 10) > 8000000) {
      this.isDisabled = true;
      this.textAlertMin = 'maksimal pengajuan penarikan Rp8.000.000'
    } else if (parseInt(valEnd, 10) > this.balance.commission) {
      this.isDisabled = true;
      this.textAlertMin = 'saldo tidak mencukupi'
    } else if (parseInt(valEnd, 10) < 10000) {
      this.isDisabled = true;
      this.textAlertMin = 'minimal pengajuan penarikan Rp10.000'
    } else {
      this.isDisabled = false;
      this.textAlertMin = ''
    }

    this.paramsWD.nominal = parseInt(valEnd, 10)
    this.filterResult.emit(this.paramsWD)
    
  }
  formatNumber(n: any) {
    return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
  getBalance(): void {
    this.balanceService
      .balance()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: ResponseCustomType<BalanceResponseType>) => {
          this.balance = res.data;
          
        },
        (error) => {}
      );
  }
  ngOnDestroy(): void {
    // this.destroy$.unsubscribe();
  }
}