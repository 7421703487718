<div class="mt-4 d-flex align-items-center justify-content-center">
  <h4>Buat Ticket</h4>
</div>
<form
[formGroup]="formGroup"
  action=""
  class="offcanvas-body d-flex flex-column"
  style="align-content: space-between"
  (keydown.enter)="$event.preventDefault()"
>
  <div class="mt-2 px-4">
      <label for="" class="text-bold">Nomor Resi</label>
      <input
        id="typeahead-template"
        [(ngModel)]="awb"
        type="search"
        class="form-control"
        [ngbTypeahead]="search"
        #inputValue
        [inputFormatter]="formatter"
        [resultFormatter]="formatter"
        selectOnExact
        placeholder="Ketik atau Paste resi"
        required
        formControlName="awbb"
        (ngModelChange)="onSearchAwb($event)"
        (keydown.enter)="$event.preventDefault()"
      />
      <small *ngIf="isNotFound" class="text-danger">Nomor Resi Tidak Ditemukkan atau Sudah Terbuat Tiketnya</small>
  </div>
  <div class="mt-4 px-4">
      <label for="" class="text-bold">Customer</label>
      <input
        [(ngModel)]="customer"
        placeholder="Masukan nama customer"
        class="form-control rounded"
        type="text"
        name="customer"
        formControlName="customer"
        readonly
      />
  </div>
  <div class="mt-4 px-4">
    <label for="" class="text-bold text-black">Jenis Tiket</label>
    <select class="form-select" aria-label="Default select example" formControlName="type_ticket"  placeholder="Pilih Jenis Tiket">
      <option selected disabled>Pilih Jenis Tiket</option>
      <option [value]="listTypeTicket[i].id" *ngFor="let items of listTypeTicket; let i = index">
        {{items.name}}
      </option>
    </select>
    
  </div>
  <div class="mt-4 px-4">
      <label for="" class="text-bold">Deskripsi</label>
      <textarea
        nz-input
        class="rounded"
        [(ngModel)]="description"
        placeholder="Mohon tuliskan dengan jelas perbedaan alamat SEBELUM dan SESUDAH. Jika ada penambahan ongkir berdasarkan info dari ekspedisi, akan ditagihkan secara manual dengan mengurangi saldo kamu."
        [nzAutosize]="{ minRows: 5, maxRows: 7 }"
        name="description"
        formControlName="description"
      ></textarea>
  </div>
  <div class="d-flex mt-4 px-4 align-items-center">
    <input type="file" name="file" (change)="handleFileUpload($event)" #fileupload class="form-control d-none" id="attachment" multiple>
    <button *ngIf="fileUpload.length === 0" class="btn d-flex align-items-center p-0" (click)="triggerFileInput()">
      <img
        src="https://storage.googleapis.com/komerce/assets/icons/document-link.svg"
        alt=""
        width="16px"
      />
      <span class="mx-2" style="color: #08a0f7; font-size: 12px;">Lampirkan File</span>
    </button>
    <div [ngClass]="fileUpload.length > 1 ? 'd-flex flex-column' : ''">
      <div *ngFor="let file of fileUpload; let i = index" class="d-flex align-items-center" style="margin-bottom: 5px;">
        <img
        src="https://storage.googleapis.com/komerce/assets/icons/document-link.svg"
        alt=""
        width="16px"
      />
        <span class="mx-2" style="color: #08a0f7; font-size: 12px;">{{ file.name }}</span>
        <button class="btn d-flex justify-content-center align-items-center" style="background-color: rgb(242, 242, 242); height: 18px; width: 14px;" (click)="deleteFile(i)">
          <img src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/trash.svg" alt="" width="12px">
        </button>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end p-4 gap-2">
    <button class="btn btn-outline-primary rounded" (click)="cancel()">
      <span class="text-bold">Batal</span>
    </button>
    <button class="btn rounded" (click)="createTicket()" [disabled]="formGroup.invalid"
    [ngClass]="formGroup.invalid ? 'btn-secondary' : 'btn-primary text-white'">
      <span class="text-bold">Buat Tiket</span>
    </button>
  </div>
</form>
