import { Component } from '@angular/core';

@Component({
  selector: 'komerce-date-range-picker',
  templateUrl: './komerce-date-range-picker.component.html',
  styleUrls: ['./komerce-date-range-picker.component.scss']
})
export class KomerceDateRangePickerComponent {

}

