import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction, Subject, debounceTime, map, takeUntil } from 'rxjs';
import { TicketService } from 'src/app/services/api/ticket.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-modal-create-ticket',
  templateUrl: './modal-create-ticket.component.html',
  styleUrls: ['./modal-create-ticket.component.scss']
})
export class ModalCreateTicketComponent {
  awb!: string
  customer!: string
  description: string = ''
  typeTicket: string = ''
  isDisabled: boolean = true
  fileUpload: File[] = [];
  filename: string = 'Lampirkan File'
  destroy$ = new Subject();
  listTypeTicket: any = [];
  listAwb: any[] = [];
  isNotFound: boolean = false;
  @Output() filterResult = new EventEmitter();
  @ViewChild('fileupload') fileUploadRef!: ElementRef;
  private searchSubject: Subject<any> = new Subject()
  formGroup: FormGroup = new FormGroup({
    awbb: new FormControl('', [Validators.required]),
    customer: new FormControl('', [Validators.required]),
    type_ticket: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
  });
  @Input() getTableList!: () => void;
  
  constructor(
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    private ticketService: TicketService,
  ) {
  }
  ngOnInit(): void {
    this.getTypeTicket()

    this.searchSubject.pipe(
      debounceTime(300)
    ).subscribe((value: any) => {
      if (value === '' || value.length < 4 ) {
        this.customer = ''
      } if(typeof value === 'object'){
        this.awb = value.awb
      } else {
        this.awb = value
        this.getListAwb(value)
      }
    });
  }

  formatter = (x: { awb: string }) =>
    x.awb;

  search: OperatorFunction<
    string,
    readonly { awb: string }[]
  > = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      map((term) =>
        term === '' ? [] : this.listAwb
      )
    );

  get form() {
    return {
      formGroup: this.formGroup,
      awb: this.formGroup.get('awbb'),
      customer: this.formGroup.get('customer'),
      type_ticket: this.formGroup.get('type_ticket'),
      description: this.formGroup.get('description'),
    };
  }

  triggerFileInput() {
    this.fileUploadRef.nativeElement.click();
  }
  cancel() {
    this.activeModal.dismiss();
    this.isNotFound = false
  }
  deleteFile(i: number) {
    this.fileUpload.splice(i, 1);
    this.filename = this.fileUpload.length > 0 ? `${this.fileUpload.length} Files` : 'Lampirkan File';
  }
  handleFileUpload(event: any) {
    const files: File[] = event.target.files;
    if (this.fileUpload.length + files.length > 3) {
      this.toastService.show(null, 'Anda hanya dapat melampirkan 3 file', 'danger', {
        classname: 'bg-danger',
        delay: 3000,
      });
      return;
    }
    
    for (let file of files) {
      const allowedFormats = ['png', 'jpg', 'jpeg', 'pdf'];
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (!allowedFormats.includes(fileExtension as string)) {
        this.toastService.show(null, `Format file '.${fileExtension}' tidak diperbolehkan.`, 'danger', {
          classname: 'bg-danger',
          delay: 3000,
        });
        return;
      }

      this.fileUpload.push(file);
    }
    this.filename = this.fileUpload.length > 1 ? `${this.fileUpload.length} Files` : this.fileUpload[0].name;
  }
  getTypeTicket() {
    const params = {
      all: false
    }
    this.ticketService
      .typeTicket(params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.listTypeTicket = res.data.types
        },
        () => {}
      );
  }
  onSearchAwb(v: any) {
    if (v === '' || v === undefined || typeof v === 'object' || v.length < 4) {
      this.customer = ''
      this.isNotFound = false
      if (typeof v === 'object') {
        this.customer = this.formGroup.get('awbb')?.value.receiver_name
      }
      return
    } else {
      this.searchSubject.next(v);
    }
}
  getListAwb(v: string) {
    if (v === '' || v === undefined) {
      return
    } else {
      this.ticketService
      .listAwb(this.awb)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.listAwb = res.data.awbs
          this.isNotFound = false
        },
        (err) => {
          this.isNotFound = true
        }
      );
    }
  }
  inputTypeTicket(event: any): void {
    const selectedType = event.target.value
    this.typeTicket = selectedType
  }
  createTicket() {
        const formdata = new FormData();
        formdata.append("awb", this.formGroup.get('awbb')?.value.awb);
        formdata.append("type_ticket_id", this.formGroup.get('type_ticket')?.value);
        formdata.append("description", this.formGroup.get('description')?.value);
        this.fileUpload.forEach((file, index) => {
          formdata.append("attachments", file);
        });

            this.ticketService
                .createTicket(formdata)
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                    (res) => {
                        this.activeModal.dismiss();
                        this.isNotFound = false
                        this.toastService.show(null, 'Berhasil membuat ticket', 'success', {
                            classname: 'bg-success',
                            delay: 3000,
                        });
                        window.location.reload()
                    },
                    (error) => {
                        console.error('Error creating ticket:', error);
                        this.toastService.show(null, 'Gagal membuat ticket', 'error', {
                            classname: 'bg-danger',
                            delay: 3000,
                        });
                    }
                );
        }

    ngOnDestroy(): void {
      this.destroy$.unsubscribe();
    }
}
