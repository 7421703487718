import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/api/user.service';
import { PinModel } from 'src/app/models/pin.model';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-modal-pin',
  templateUrl: './modal-pin-verification.component.html',
  styleUrls: ['./modal-pin-verification.component.scss'],
})
export class ModalPinComponent implements OnInit {
  pin!: PinModel
  settings: any = {
    length: 6,
    numbersOnly: true,
  };
  @Input() otpParams: any = {
    pin: '',
  };
  otp: string = '';
  isErrorMessage: boolean = false;
  destroy$ = new Subject();
  isDisabled: boolean = true;
  url: string = '';

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private userService: UserService,
    private router: Router,
    private toastService: ToastService,
  ) {}
  ngOnInit(): void {
    this.url = this.router.url;
  }
  handleClose(e: any): void {
      if(this.url = 'pages/pengaturan/rekening') {
        this.router.navigate(['/pages/dashboard'])
        this.activeModal.dismiss()
      } else {
        this.activeModal.dismiss()
      }
  }
  selectedItem(e: any) {}

  onChange(e: string) {
    this.otp = e;
    if (e.length === 6) {
      this.isDisabled = false;
    }
  }
  handleConfirm(e:any) {
    this.otpParams.pin = this.otp
    this.userService
      .checkPin(this.otpParams)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {

            if (res.data.is_match === true) {
              this.pin = res.data

              if (this.url === '/pages/pengaturan/profile') {
                this.toastService.show(null, 'Berhasil melihat Token API', 'success', {
                  classname: 'bg-success',
                  delay: 3000,
                }); 
              }

              this.activeModal.dismiss();
            } else {
              this.toastService.show(null, 'PIN yang Kamu masukkan salah', 'danger', {
                classname: 'bg-danger',
                delay: 3000,
              });
              this.isErrorMessage = true
            }
      })
  }
  onKeyDown(event: KeyboardEvent) {
    if (event.code === 'Backspace') {
      this.isErrorMessage = false;
      this.isDisabled = true;
    }
  }

}
