<nav id="sidebar" class="sidebar position-fixed card pe-3 bg-white h-100 mt-4">
  <div class="card-body d-flex flex-column" style="position: relative">
    <div
      [ngClass]="active ? 'card-body' : 'card-body p-3'"
      class="d-flex flex-column flex-grow-0"
    >
      <ng-container
        *ngIf="
          profileData.is_rajaongkir === false || profileData.is_openapi === true
        "
      >
        <ng-container *ngFor="let item of menuItems">
          <a
            [routerLink]="item.link"
            class="nav-link navItemClass"
            routerLinkActive="active-link"
            *ngIf="item.name !== 'Billing'"
          >
            <div
              [ngClass]="{
                'd-flex flex-row nav justify-content-start ps-3 pe-3': true,
                active: activeButton === item.name
              }"
              (click)="activeButton = item.name"
            >
              <img
                class="non"
                [ngClass]="active ? 'me-2' : 'me-0'"
                [src]="
                  activeButton === item.name
                    ? item.icon.active
                    : item.icon.default
                "
                alt=""
              />
              <img class="hov me-2" [src]="item.icon.active" alt="" />
              <span *ngIf="active" class="link">{{ item.label }}</span>
            </div>
          </a>
        </ng-container>
      </ng-container>

      <ng-container
        *ngIf="
          profileData.is_rajaongkir === true || profileData.is_openapi === false
        "
      >
        <ng-container *ngFor="let item of menuItems">
          <ng-container *ngIf="item.name === 'Billing'">
            <a
              [routerLink]="item.link"
              class="nav-link navItemClass"
              routerLinkActive="active-link"
            >
              <div
                [ngClass]="{
                  'd-flex flex-row nav justify-content-start ps-3 pe-3': true,
                  active: activeButton === item.name
                }"
                (click)="activeButton = item.name"
              >
                <img
                  class="non"
                  [ngClass]="active ? 'me-2' : 'me-0'"
                  [src]="
                    activeButton === item.name
                      ? item.icon.active
                      : item.icon.default
                  "
                  alt=""
                />
                <img class="hov me-2" [src]="item.icon.active" alt="" />
                <span *ngIf="active" class="link">{{ item.label }}</span>
              </div>
            </a>
          </ng-container>
        </ng-container>
      </ng-container>

      <div (click)="toggleSettings()">
        <div
          [ngClass]="{
            'collapsed d-flex flex-row nav justify-content-start ps-3 pe-3': true,
            active: activeButton === 'Pengaturan'
          }"
          data-bs-toggle="collapse"
        >
          <img
            class="non me-2"
            [src]="
              activeButton === 'Pengaturan'
                ? icons.settings.active
                : icons.settings.default
            "
            alt=""
          />
          <img class="hov me-2" [src]="icons.settings.active" alt="" />
          <span class="link">Pengaturan</span>
          <img
            class="ms-2"
            [src]="isOpen ? icons.arrow.down : icons.arrow.up"
            alt=""
          />
        </div>
      </div>

      <div #collapse="ngbCollapse" [(ngbCollapse)]="isOpen">
        <ng-container *ngFor="let subItem of subMenuItems">
          <a
            [routerLink]="subItem.link"
            class="accordion accordion-flush nav-link navItemClass"
            routerLinkActive="active-link"
          >
            <div
              [ngClass]="{
                'collapsed d-flex flex-row nav justify-content-start ps-3 pe-3': true,
                active: activeButton === subItem.name
              }"
              (click)="activeButton = subItem.name"
            >
              <span class="link pe-2">{{ subItem.label }}</span>
            </div>
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</nav>
