import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { EmptyDataTableComponent } from './components/empty-data-table/empty-data-table.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PaginiationComponent } from './components/pagination/pagination.component';



@NgModule({
  declarations: [
    PaginiationComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    // PaginiationComponent
  ],
  exports: [
    PaginiationComponent,
    CommonModule,
  ]
})
export class SharedModuleDataOrder { }
