<div class="card">
  <div class="d-flex justify-content-between align-items-center">
    <div class="p-3 d-flex align-items-center">
      <button
        class=""
        style="border: none; background-color: transparent"
        (click)="back()"
      >
        <img
          src="https://storage.googleapis.com/komerce/assets/icons/back.svg"
          alt=""
          width="40px"
        />
      </button>
      <span
        class="text-black"
        style="font-size: 20px; font-weight: 600; margin-left: 15px"
        >Rincian Saldo</span
      >
    </div>
    <button
      class="btn btn-primary text-white me-3"
      style="height: 40px"
      (click)="handleDownload()"
    >
      Download
    </button>
  </div>
  <hr />
  <div class="p-3 d-flex justify-content-between flex-wrap">
    <div class="col-lg-4 border rounded d-flex flex-grow-0 align-items-center">
      <span class="py-4 px-2" style="font-size: 18px">Saldo: </span>
      <span
        class="px-2"
        style="color: #f95031; font-size: 18px; font-weight: 600"
      >
        {{ balance.balance | idr }}
      </span>
    </div>
    <div
      class="d-flex justify-content-end flex-row flex-grow-1 gap-2 align-items-center mt-2"
    >
      <div class="d-flex align-items-center">
        <div class="form-group has-search d-flex align-items-center">
          <span class="form-control-feedback"
            ><i class="icon-search-normal-1"></i
          ></span>
          <input
            [(ngModel)]="filterHistory.airway_bill"
            (ngModelChange)="onChangeSearch($event)"
            type="text"
            class="form-control"
            placeholder="No Resi"
          />
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div ngbDropdown class="d-inline-block">
          <button
            type="button"
            class="btn btn-primary btn-filter"
            id="dropdownfilterorder"
            ngbDropdownToggle
          >
            <i class="icon-filter-search text-white"></i>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownfilterorder">
            <button ngbDropdownItem disabled class="text-black">
              <b>Filter</b>
            </button>
            <div ngbDropdown placement="left" class="d-inline-block w-100">
              <button
                type="button"
                class="btn w-100"
                id="dropdownstatus"
                style="text-align: start"
                ngbDropdownToggle
              >
                Jenis Transaksi
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownstatus">
                <button class="btn w-100" style="text-align: start">
                  <div
                    class="form-check"
                    *ngFor="let list of status; let i = index"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      (value)="(list.value)"
                      (id)="(list.id)"
                      (change)="getStatus($event, list)"
                      [checked]="list.checked"
                    />
                    <label
                      class="form-check-label"
                      [for]="list.id"
                      style="font-size: 12px; text-wrap: nowrap"
                    >
                      {{ list.label }}
                    </label>
                  </div>
                </button>
              </div>
              <button
                type="button"
                class="btn w-100 ngx-daterangepicker-action"
                id="dropdownstatus"
                style="text-align: start"
                (click)="openDatepicker()"
              >
                Periode
              </button>
              <div id="dropdownstatus" class="" container="body">
                <input
                  type="text"
                  class="btn w-100"
                  ngxDaterangepickerMd
                  [(ngModel)]="selected"
                  [ngModelOptions]="{ standalone: true }"
                  [showCustomRangeLabel]="false"
                  [keepCalendarOpeningWithRange]="true"
                  [alwaysShowCalendars]="true"
                  [ranges]="ranges"
                  [linkedCalendars]="true"
                  [isInvalidDate]="isInvalidDate"
                  [showCancel]="true"
                  opens="center"
                  drops="down"
                  [locale]="setlocale"
                  (change)="onChangeDate($event)"
                  style="border: none; font-size: 14px; display: none"
                  #datePickerInput
                  container="body"
                />
              </div>
              <div>
                <button
                  class="btn w-100"
                  style="text-align: start; color: #f95031"
                  (click)="resetFilter()"
                >
                  Reset Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="d-flex flex-column table-wrapper mt-3 w-100 p-4"
    style="overflow-x: auto; z-index: 0"
  >
    <table class="table table-hover table-responsive">
      <thead class="sticky-top" style="background-color: rgb(245, 245, 245)">
        <tr style="font-size: 16px">
          <th scope="col">Tanggal</th>
          <th scope="col">Jenis Transaksi</th>
          <th scope="col">Jumlah</th>
          <th scope="col">Saldo</th>
          <th scope="col">Aksi</th>
        </tr>
      </thead>
      <tbody
        class="tabb-body"
        style="overflow: auto"
        *ngIf="HistoryList !== null"
      >
        <tr *ngFor="let items of HistoryList; let i = index">
          <td>
            <div class="d-flex flex-column">
              <div>
                {{
                  items.transaction_date
                    | date : "dd MMMM yyyy" : "id-ID" || "-"
                }}
              </div>
              <div class="text-muted">
                {{ items.transaction_date.slice(10, -3) || "-" }} WIB
              </div>
            </div>
          </td>
          <td>
            <div>
              <strong
                >{{ typePayment(items.payment_method) }} (
                <span [ngClass]="classTransaction(items.transaction_type)">{{
                  typeTransaction(items.transaction_type)
                }}</span>
                )</strong
              >
            </div>
            <div class="d-flex gap-4">
              <img
                [src]="items.shipping_logo"
                alt=""
                [style.width.px]="
                  items.shipping_logo ===
                  'https://storage.googleapis.com/komship-bucket/shipment/sicepat-logo.png'
                    ? 80
                    : 40 &&
                      items.shipping_logo ===
                        'https://storage.googleapis.com/komship-bucket/shipment/sap-express-logo.png'
                    ? 35
                    : 40
                "
              />
              <img
                *ngIf="items.shipping !== '' && items.awb !== ''"
                src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                alt=""
                width="20px"
                [ngbPopover]="popContent"
                style="cursor: pointer"
              />
            </div>
            <ng-template #popContent
              ><b>Nomor Resi:</b> {{ items.awb }}
              <button
                class="btn p-0"
                (click)="salinResi(items.awb); $event.stopPropagation()"
              >
                <img
                  class="ms-2"
                  src="https://storage.googleapis.com/komerce/assets/icons/icons-copy.svg"
                  alt=""
                />
              </button>
            </ng-template>
          </td>
          <td>
            <div
              [ngClass]="{
                'text-danger': items.type === 'debit',
                'text-success': items.type === 'credit'
              }"
            >
              <span *ngIf="items.type === 'debit'"
                >- {{ items.nominal | idr }}</span
              >
              <span *ngIf="items.type === 'credit'"
                >+ {{ items.nominal | idr }}</span
              >
            </div>
          </td>
          <td>
            <div>{{ items.balance | idr }}</div>
          </td>
          <td>
            <div
              *ngIf="
                items.reference_transaction_id === null ||
                items.transaction_type === 'withdrawal'
              "
            >
              -
            </div>
            <div
              *ngIf="
                items.reference_transaction_id !== null &&
                items.transaction_type !== 'withdrawal'
              "
            >
              <a
                [routerLink]="[
                  '/pages/data-order/detail-order',
                  items.reference_transaction_id
                ]"
              >
                <button class="btn link">Lihat Detail</button>
              </a>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="HistoryList === null">
        <tr>
          <td colspan="6" class="empty">Tidak ada data yang ditampilkan</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
