<div class="login-container">
  <img
    src="https://storage.googleapis.com/komerce/assets/illustration/bg-yellow-ro.png"
    class="background"
  />
  <div class="content-login">
    <div
      class="w-100 h-100 gap-3 d-flex align-items-center justify-content-center"
    >
      <div
        class="d-flex flex-column gap-3 col-lg-6 col-xl-5 col-12 col-md-6 justify-content-center text-center"
      >
        <div class="logo-login">
          <img
            style="max-height: 50px"
            src="https://storage.googleapis.com/komerce/core/RO%20by%20Komerce.svg"
          />
        </div>
        <div>
          <div
            class="px-md-5 px-3 py-5 m-3 card shadow d-flex flex-column gap-3 justify-content-center text-center align-items-center"
          >
            <div
              *ngIf="title === 'Sedang Verifikasi'"
              class="cssload-speeding-wheel"
            ></div>
            <img
              *ngIf="title !== 'Sedang Verifikasi'"
              style="max-height: 60px"
              src="https://storage.googleapis.com/komerce/assets/svg/Icon-Error.svg"
            />
            <h5>
              <strong>{{ title }}</strong>
            </h5>
            <span
              *ngIf="
                title !== 'Sedang Verifikasi' &&
                title !== 'Verifikasi Gagal, Silakan Ulangi Kembali'
              "
              >Silakan
              <a href="/login" class="text-yellow">masuk</a> menggunakan Email
              yang terdaftar</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
