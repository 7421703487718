<form [action]="">
  <div
    class="d-flex flex-column gap-3 justify-content-center align-items-center"
  >
    <div
      class="d-flex w-100 ps-4 pe-4 flex-column gap-3 justify-content-center mt-4"
    >
      <div class="title text-center">Penarikan Saldo</div>

      <div class="mt-3 w-100">
        <label for="exampleInputPassword1" class="form-label"
          >Nominal Penarikan</label
        >
        <input
          type="text"
          name="accountNumber"
          numberOnly
          myCurrencyFormatter
          class="form-control w-100"
          id="nominal-input"
          placeholder="minimal Rp10.000,-, maksimal Rp8.000.000,-"
          [(ngModel)]="nominal"
          (ngModelChange)="handleNominal($event)"
        />
        <small class="text-danger">{{textAlertMin}}</small>
      </div>
      <div>
        <label for="typeahead-template" class="form-label">Rekening</label>
          <select class="form-select" aria-label="Default select example" (change)="inputBank($event)">
            <option [value]="states[i].id" *ngFor="let items of states, let i = index" (ngModelChange)="inputBank($event)">{{states[i].bank_code}} - {{states[i].bank_number}} - {{states[i].bank_owner}}</option>
          </select>
        <small>Pastikan Rekening yang kamu pilih sudah benar</small>
      </div>

      <div class="w-100 mb-3 mt-2 d-flex justify-content-center gap-3">
        <button (click)="handleClose($event)" class="btn btn-outline-primary w-50">Batal</button>
        <button (click)="handleConfirm(verifPIN)" class="btn btn-primary w-50" [disabled]="isDisabled">
          <div class="ps-3 pe-3" style="color: white">Ajukan Penarikan</div>
        </button>
      </div>
    </div>
  </div>
</form>
<ng-template #verifPIN let-c="close" let-d="dismiss">
  <div class="d-flex flex-column gap-0 justify-content-center align-items-center">
    <div class="d-flex w-100 ps-4 pe-4 flex-column gap-3 justify-content-center">
      <div class="title text-center mt-4">Masukkan PIN</div>
      <div class="text-center">Konfirmasi PIN kamu untuk melanjutkan</div>

      <div class="mt-3 w-100 d-flex justify-content-center align-items-center gap-1">
        <otp [setting]="settings" (keydown)="onKeyDown($event)" (onValueChange)="onChange($event)"></otp>
      </div>
      <div *ngIf="isErrorMessage === true" class="p-2 my-1" style="background-color: #FFECE9;border-radius: 8px;">
        <small class="text-danger">PIN yang kamu masukkan salah</small>
      </div>
      <div class="w-100 mb-3 mt-2 d-flex justify-content-center gap-3">
          <button class="btn btn-outline-primary w-50" (click)="close(verifPIN)">Kembali</button>
        <button [disabled]="isDisabled" (click)="handleVerif(verifPIN)"
          [class]="isDisabled ? 'btn btn-secondary w-50' : 'btn btn-primary w-50 text-white'">
          <div class="ps-3 pe-3">Konfirmasi</div>
        </button>
      </div>
    </div>
  </div>
</ng-template>