import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { Profile } from 'src/app/models/profile.model';
import { UserService } from 'src/app/services/api/user.service';
import { BaseService } from 'src/app/services/base.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() active: boolean = true;
  @Output() collapseToogle = new EventEmitter();
  destroy$ = new Subject<void>();
  detailProfile!: Profile;
  private profileSubscription!: Subscription;

  constructor(
    private storageService: LocalStorageService,
    private router: Router,
    private baseService: BaseService,
    private userService: UserService,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    // Fetch profile data if needed
    this.profileService.getProfile();

    // Subscribe to profileData$ to receive profile updates
    this.profileSubscription = this.profileService.profileData$.subscribe(
      (data) => {
        if (data) {
          this.detailProfile = data;
        }
      },
      (error) => {
        console.error('Error fetching profile data:', error);
      }
    );
  }

  collapseClick(): void {
    this.active = !this.active;
    this.collapseToogle.emit(this.active);
  }

  logout(): void {
    this.storageService.signOut();
    this.router.navigate(['/login']);
  }

  ngOnDestroy(): void {
    // Properly unsubscribe when component is destroyed
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
