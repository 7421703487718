import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { navigation } from '../../../../constants/navigation.const';
import { ProfileService } from 'src/app/services/profile.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  navigation = navigation;
  @Output() isActive = new EventEmitter();
  @Input() active: boolean = false;
  public isOpen = true;
  activeButton: string = 'dashboard';
  isRajaOngkir: boolean = false;
  profileData!: any;
  private profileSubscription!: Subscription;
  constructor(
    private elementRef: ElementRef,
    private route: Router,
    private activeRoute: ActivatedRoute,
    private profileService: ProfileService
  ) {
    route.events.subscribe((val) => {
      const nav = val as NavigationEnd;

      if (nav.url === '/pages/dashboard') {
        this.activeButton = 'dashboard';
        this.isOpen = true;
      } else if (nav.url === '/pages/pengaturan/profile') {
        this.activeButton = 'Profile';
        this.isOpen = false;
      } else if (nav.url === '/pages/pengaturan/pin') {
        this.activeButton = 'PIN';
        this.isOpen = false;
      } else if (nav.url === '/pages/pengaturan/rekening') {
        this.activeButton = 'Rekening Bank';
        this.isOpen = false;
      } else if (nav.url === '/pages/keuangan/saldo') {
        this.activeButton = 'Keuangan';
        this.isOpen = true;
      } else if (nav.url === '/pages/keuangan/komisi') {
        this.activeButton = 'Keuangan';
        this.isOpen = true;
      } else if (nav.url === '/pages/keuangan/saldo/detail') {
        this.activeButton = 'Keuangan';
        this.isOpen = true;
      } else if (nav.url === '/pages/keuangan/komisi/detail') {
        this.activeButton = 'Keuangan';
        this.isOpen = true;
      } else if (nav.url.startsWith('/pages/keuangan/saldo/detail/')) {
        this.activeButton = 'Keuangan';
        this.isOpen = true;
      } else if (nav.url === '/pages/data-order') {
        this.activeButton = 'Data Order';
        this.isOpen = true;
      } else if (nav.url.startsWith('/pages/data-order/detail-order/')) {
        this.activeButton = 'Data Order';
        this.isOpen = true;
      } else if (nav.url === '/pages/ticketing') {
        this.activeButton = 'Kendala';
        this.isOpen = true;
      } else if (nav.url === '/pages/billings') {
        this.activeButton = 'Billing';
        this.isOpen = true;
      } else if (nav.url.startsWith('/pages/ticketing/detail/')) {
        this.activeButton = 'Kendala';
        this.isOpen = true;
      }
    });
  }
  inside = false;
  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.isActive.emit(false);
    }
  }
  ngOnInit() {
    this.profileSubscription = this.profileService.profileData$.subscribe(
      (data) => {
        if (data) {
          this.profileData = data;
        }
      }
    );
  }

  setNavActive(data: any): void {
    this.isActive.emit(false);
  }

  ngOnDestroy(): void {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
  }
}
