import {
    Component,
    OnDestroy,
    OnInit,
    EventEmitter,
    Input,
    NgModule,
    Output,
    LOCALE_ID,
    ViewChild,
  } from '@angular/core';
  import { Subject, takeUntil } from 'rxjs';
  import { Router, RouterModule, Routes, ActivatedRoute } from '@angular/router';
  import { FormsModule } from '@angular/forms';
  import { registerLocaleData } from '@angular/common';
  import localeId from '@angular/common/locales/id';
  import { DataOrderModel, OrderModel } from 'src/app/models/data-order.model';
  import { OrderService } from 'src/app/services/api/order.service';
  import { DetailDataOrderModel, orderDetails } from 'src/app/models/detail-data-order.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { awbHistoryModel } from 'src/app/models/awb-history.model';
  
  
  @Component({
    selector: 'app-detail-data-order',
    templateUrl: './detail-data-order.component.html',
    styleUrls: ['./detail-data-order.component.scss'],
    providers: [{ provide: LOCALE_ID, useValue: 'id' }]
  })
  export class DetailDataOrderComponent {
    @Input() detailOrder!: DetailDataOrderModel
    orderDetails: orderDetails[] = []
    awbHistory: awbHistoryModel[] = []
    destroy$ = new Subject();
    orderID!: number
    public isCollapsed = true;
    totalPrice!: number
    shipping!: string
    awb!: string
    isAwbAvail!: boolean

    @Input() paramAwbHistory: any = {
      shipping: '',
      airway_bill: '',
    }
    
    constructor(
      private orderService: OrderService,
      private route: Router,
      private activeRoute: ActivatedRoute,
      private modalService: NgbModal
      ) {
    }
    ngOnInit(): void {
      
      
      registerLocaleData(localeId);
      this.getDetailOrder()
      

      this.activeRoute.params.subscribe(params => {
        this.orderID = Number(params['id'])        
      });
    }

    
    back() {
      this.route.navigate(['pages/data-order'])
    }

    openLacakResi(lacakresi: any) {
      this.modalService.open(lacakresi, { scrollable: true, centered: true, size: 'md' });
      this.getAwbHistory()
    }

    getDetailOrder() {
      this.activeRoute.params.subscribe(params => {
        this.orderID = Number(params['id'])        
      });

        this.orderService
          .detailDataOrder(this.orderID)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (res) => { 
              this.detailOrder = res.data;
              this.orderDetails = res.data.order_details

              this.paramAwbHistory.shipping = res.data.shipping
              this.paramAwbHistory.airway_bill = res.data.awb

              const dateParts = this.detailOrder.order_date.split("-");
              const formattedDate = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
              this.detailOrder.formatted_date = formattedDate;

              let sum: number = res.data.order_details.map((a: any) => a.subtotal).reduce(function(a: any, b: any)
              {
                return a + b;
              });
              this.totalPrice = sum
              this.getAwbHistory()
            },
            () => {}
          );
      }
      
      getAwbHistory() {
        this.orderService
        .awbHistory(this.paramAwbHistory)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            this.awbHistory = res.data.history
            
          },
        )
      }
      iconAwb(value: string) {
        let result = ''
        if (value === 'Pickup') {
          result = 'https://storage.googleapis.com/komerce/assets/icons/resi-pickup.svg'
        } else if (value === 'Process') {
          result = 'https://storage.googleapis.com/komerce/assets/icons/resi-kirim.svg'
        } else if (value === 'Problem') {
          result = 'https://storage.googleapis.com/komerce/assets/icons/resi-problem.svg'
        } else if (value === 'Delivered') {
          result = 'https://storage.googleapis.com/komerce/assets/icons/resi-terima.svg'
        } else if (value === 'Return') {
          result = 'https://storage.googleapis.com/komerce/assets/icons/resi-retur.svg'
        } else if (value === 'Delivery') {
          result = 'https://storage.googleapis.com/komerce/assets/icons/resi-delivery.svg'
        }
        return result
      }
    }

  