import { Component } from '@angular/core';

@Component({
  selector: 'komerce-input',
  templateUrl: './komerce-input.component.html',
  styleUrls: ['./komerce-input.component.scss']
})
export class KomerceInputComponent {

}
