<div class="card p-3 d-flex flex-column gap-3">
  <div class="d-flex align-items-center">
    <Button
      style="border: none; background-color: transparent"
      (click)="back()"
    >
      <img
        src="https://storage.googleapis.com/komerce/assets/icons/back.svg"
        alt=""
        width="40px"
      />
    </Button>
    <span
      class="text-black"
      style="font-size: 20px; font-weight: 500; margin-left: 15px"
      >Detail Order</span
    >
  </div>
  <div class="mt-4">
    <span><b>Informasi Order</b></span>
    <div
      class="p-3 mt-2"
      style="border: 1px solid; border-color: grey; border-radius: 5px"
    >
      <div class="d-flex">
        <div class="">
          <p>No Order</p>
          <p>Tanggal Order</p>
          <p>Metode Pembayaran</p>
          <p>Status</p>
        </div>
        <div class="ms-4">
          <p>: {{ detailOrder.order_no || "-" }}</p>
          <p>
            :
            {{
              detailOrder.formatted_date
                | date : "dd MMMM yyyy" : "id-ID" || "-"
            }}
          </p>
          <p>: {{ detailOrder.payment_method || "-" }}</p>
          <span class="d-flex">
            :
            <p
              *ngIf="detailOrder.order_status === 'Diterima'"
              class="status-success ms-2"
            >
              {{ detailOrder.order_status || "-" }}
            </p>
            <p
              *ngIf="detailOrder.order_status === 'Dipacking'"
              class="status-info ms-2"
            >
              {{ detailOrder.order_status || "-" }}
            </p>
            <p
              *ngIf="detailOrder.order_status === 'Retur'"
              class="status-danger ms-2"
            >
              {{ detailOrder.order_status || "-" }}
            </p>
            <p
              *ngIf="detailOrder.order_status === 'Dikirim'"
              class="status-warning ms-2"
            >
              {{ detailOrder.order_status || "-" }}
            </p>
            <p
              *ngIf="detailOrder.order_status === 'Diajukan'"
              class="status-danger ms-2"
            >
              Order Dibuat
            </p>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-4">
    <span><b>Informasi Pengiriman</b></span>
    <div
      class="p-3 mt-2"
      style="border: 1px solid; border-color: grey; border-radius: 5px"
    >
      <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-end">
          <img
            src="https://storage.googleapis.com/komerce/assets/icons/profile-placehold.svg"
            alt=""
            width="40px"
          />
          <div class="ml-4" style="margin-left: 15px">
            <span>
              <b>{{ detailOrder.receiver_name || "-" }}</b> <br />
              {{ detailOrder.receiver_phone || "-" }}
            </span>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button
            *ngIf="awbHistory.length === 0"
            class="btn btn-outline-primary"
            (click)="openLacakResi(lacakresinodata)"
          >
            Lacak Resi
          </button>
          <button
            *ngIf="awbHistory.length >= 1"
            class="btn btn-outline-primary"
            (click)="openLacakResi(lacakresi)"
          >
            Lacak Resi
          </button>
        </div>
      </div>
      <div class="d-flex mt-4 justify-content-between">
        <div class="d-flex justify-content-start">
          <div class="">
            <p>Ekspedisi</p>
            <p>No Resi</p>
            <p>Detail Alamat</p>
          </div>
          <div class="ml-4" style="margin-left: 20px">
            <span>
              :
              <img
                [src]="detailOrder.shipping_logo"
                [alt]="detailOrder.shipping"
                style="max-width: 50px"
              />
            </span>
            <p></p>
            <p>: {{ detailOrder.awb || "-" }}</p>
            <p>: {{ detailOrder.receiver_address || "-" }}</p>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <div class="d-flex align-items-end">
            <p>
              Pengiriman via
              <img
                src="https://storage.googleapis.com/komerce/assets/logo/Komship-by-komerce.svg"
                alt=""
                width="80px"
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-4">
    <span><b>Informasi Penjualan</b></span>
    <div class="d-flex mt-2 table-responsive">
      <table class="table table-hover" responsive>
        <thead>
          <tr>
            <th scope="col">NO</th>
            <th scope="col">NAMA PRODUK</th>
            <th scope="col">HARGA SATUAN</th>
            <th scope="col">JUMLAH</th>
            <th scope="col">SUB TOTAL</th>
          </tr>
        </thead>
        <tbody *ngFor="let item of orderDetails; let i = index">
          <tr>
            <td>{{ i + 1 }}</td>
            <td>{{ item?.product_name }}</td>
            <td>{{ item?.product_price | idr }}</td>
            <td>{{ item?.qty }}</td>
            <td>{{ item?.subtotal | idr }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="mt-4">
    <span><b>Catatan Order</b></span>
    <div
      class="d-flex p-3 mt-2"
      style="border: 1px solid; border-color: grey; border-radius: 5px"
    >
      <div class="">
        <p style="color: grey">Tidak ada catatan</p>
      </div>
    </div>
  </div>
  <div class="">
    <div class="row d-flex mt-4 justify-content-between">
      <div class="col-12 d-flex justify-content-end">
        <div class="col-4 text-align-start">
          <p>Total Harga Produk</p>
          <p>Ongkos Kirim</p>
          <p>Biaya Lain</p>
        </div>
        <div class="col-2 tharga text-align-end">
          <p>{{ totalPrice | idr }}</p>
          <p>{{ detailOrder.shipping_cost | idr }}</p>
          <p>{{ detailOrder.additional_cost | idr }}</p>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-between garis">
      <div class="col d-flex justify-content-end text-align-start">
        <div class="col-6">
          <hr />
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-between">
      <div class="col-12 d-flex justify-content-end">
        <div class="col-4 COD text-align-start">
          <b
            [style]="
              detailOrder.order_status === 'Retur'
                ? 'text-decoration: line-through'
                : ''
            "
            >Total Pembayaran ({{ detailOrder.payment_method }})</b
          >
        </div>
        <div class="col-2 hCOD text-align-end">
          <b
            [style]="
              detailOrder.order_status === 'Retur'
                ? 'text-decoration: line-through'
                : ''
            "
            >{{ detailOrder.grand_total | idr }}</b
          >
        </div>
      </div>
    </div>
    <div class="row mt-1 d-flex justify-content-between garis">
      <div
        class="col-12 d-flex justify-content-end text-align-end align-items-center"
      >
        <div class="col-5 text-align-start">
          <hr />
        </div>
        <div class="col-1 d-flex justify-content-end text-align-end">
          <button
            *ngIf="isCollapsed"
            type="button"
            class="btn btn-open"
            (click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample"
          >
            Buka
            <img
              class="ms-2"
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
              alt=""
              width="12px"
            />
          </button>

          <button
            *ngIf="!isCollapsed"
            type="button"
            class="btn btn-open"
            (click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample"
          >
            Tutup
            <img
              class="ms-1"
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-up-2.svg"
              alt=""
              width="12px"
            />
          </button>
        </div>
      </div>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <div class="row d-flex mt-4 justify-content-between">
        <div class="col-12 d-flex justify-content-end">
          <div class="col-4 text-align-start diskon">
            <p *ngIf="detailOrder.order_status !== 'Retur'">
              Biaya COD ( 2.8% sudah termasuk PPN)
            </p>
            <p *ngIf="detailOrder.order_status === 'Retur'">
              Biaya COD ( 0% sudah termasuk PPN)
            </p>
            <p>Ongkos Kirim (dipotong Cashback -)</p>
            <p
              *ngIf="
                detailOrder.payment_method === 'BANK TRANSFER' &&
                detailOrder.order_status === 'Retur'
              "
            >
              Ongkos Kirim Pengembalian (dipotong Cashback -)
            </p>
            <p *ngIf="detailOrder.is_insurance !== 0">Biaya Asuransi</p>
          </div>
          <div class="col-2 tharga">
            <p *ngIf="detailOrder.order_status !== 'Retur'">
              -{{ detailOrder.service_fee | idr }}
            </p>
            <p *ngIf="detailOrder.order_status === 'Retur'">{{ 0 | idr }}</p>
            <p
              *ngIf="
                detailOrder.payment_method === 'BANK TRANSFER' &&
                detailOrder.order_status === 'Retur'
              "
            >
              -{{
                detailOrder.shipping_cost - detailOrder.shipping_cashback | idr
              }}
            </p>
            <p>
              -{{
                detailOrder.shipping_cost - detailOrder.shipping_cashback | idr
              }}
            </p>
            <p *ngIf="detailOrder.is_insurance !== 0">
              -{{ detailOrder.insurance_value || 0 | idr }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-between">
      <div class="col-12 d-flex justify-content-end text-align-start net-total">
        <div class="col-4">
          <b>Penghasilan bersih yang kamu dapatkan</b>
        </div>
        <div
          *ngIf="detailOrder.order_status !== 'Retur'"
          class="col-2 net text-align-end"
        >
          <b>{{
            detailOrder.grand_total -
              detailOrder.service_fee -
              (detailOrder.shipping_cost - detailOrder.shipping_cashback) -
              detailOrder.insurance_value | idr
          }}</b>
        </div>
        <div
          *ngIf="
            detailOrder.order_status === 'Retur' &&
            detailOrder.payment_method !== 'BANK TRANSFER'
          "
          class="col-2 net-lost text-align-end"
        >
          <b>
            -{{
              detailOrder.shipping_cost -
                detailOrder.shipping_cashback +
                detailOrder.insurance_value | idr
            }}</b
          >
        </div>
        <div
          *ngIf="
            detailOrder.order_status === 'Retur' &&
            detailOrder.payment_method === 'BANK TRANSFER'
          "
          class="col-2 net-lost text-align-end"
        >
          <b>
            -{{
              (detailOrder.shipping_cost - detailOrder.shipping_cashback) * 2 +
                detailOrder.insurance_value | idr
            }}</b
          >
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #lacakresinodata let-modal no-header>
  <div class="modal-body">
    <div class="d-flex mb-4 justify-content-between">
      <h5 class="modal-title justify-content-start">Riwayat Perjalanan</h5>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <p>
      Data riwayat perjalan tidak ditemukan. <b>Bisa jadi</b> sudah request
      pickup/dijemput kurir saat pickup namun <b>belum di-scan</b> QR code untuk
      memulai perjalanan di kantor cabang. Harap menunggu.
    </p>
  </div>
</ng-template>
<ng-template #lacakresi let-modal no-header>
  <div class="modal-body">
    <div class="sticky-top" style="background-color: white">
      <div class="d-flex mb-4 justify-content-between">
        <h5 class="modal-title justify-content-start">
          Riwayat Perjalanan
          <img
            *ngIf="detailOrder.shipping === 'JNE'"
            src="https://storage.googleapis.com/komerce/assets/expedisi/logo-jne.png"
            alt=""
            width="40px"
          />
          <img
            *ngIf="detailOrder.shipping === 'SICEPAT'"
            src="https://storage.googleapis.com/komerce/assets/logo/sicepat.svg"
            alt=""
            width="80px"
          />
          <img
            *ngIf="detailOrder.shipping === 'IDEXPRESS'"
            src="https://storage.googleapis.com/komerce/assets/logo/idexpress.svg"
            alt=""
            width="40px"
          />
          <img
            *ngIf="detailOrder.shipping === 'SAP'"
            src="https://storage.googleapis.com/komerce/assets/svg/sap.svg"
            alt=""
            width="40px"
          />
        </h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        ></button>
      </div>
      <div class="divider mb-4"></div>
    </div>
    <div class="p-2 w-90" style="height: 500px; overflow-y: scroll !important">
      <div *ngFor="let awb of awbHistory; let i = index" class="ms-2">
        <div class="d-flex">
          <div class="d-flex flex-column align-items-center">
            <img [src]="iconAwb(awbHistory[i].status)" alt="" />
            <img
              *ngIf="i !== awbHistory.length - 1"
              src="https://storage.googleapis.com/komerce/assets/illustration/Line-Ticketing.svg"
              alt=""
              height="70px"
            />
          </div>
          <div class="ms-3">
            <span
              >{{ awbHistory[i].date | date : "dd MMMM yyyy" }} -
              {{ awbHistory[i].date.slice(10) }}</span
            >
            <br />
            <strong style="color: rgb(84, 84, 84)">{{
              awbHistory[i].desc | uppercase
            }}</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
