<div class="d-flex flex-column gap-3 justify-content-center align-items-center">
    <div class="d-flex mb-4 flex-column justify-content-center gap-3 ps-5 pe-5 align-items-center" style="width: 500px;">
      <div class="overflow-hidden d-flex align-items-center justify-content-center" style="margin-top: -50px;">
        <img height="500px" src="https://storage.googleapis.com/komerce/assets/illustration/under-maintenance-API.svg" alt="">
      </div>
      <div style="margin-top: -190px;">
        <div class="title text-center">
          Website under maintenance...
        </div>
        <div class="desc text-center">
         <p>Our Website is currently undergoing scheduled maintenance. We should be back shorlty. Thank you for patience</p>
        </div>
        <button (click)="handleClose($event)" class="btn btn-primary w-100 text-white">Ok</button>
      </div>
    </div>
  
   </div>
  