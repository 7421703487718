import {Injectable} from "@angular/core";
import {BaseService} from "../base.service";
import {AccumulationDataOrderRequsetType} from "../../types/request/accumulation-data-order-requset.type";
import {ACCUMULATION_DATA_ORDER, AWB_HISTORY, DATA_ORDER, DETAIL_DATA_ORDER, DOWNLOAD_ORDER} from "../../constants/endpoint.const";
import {DataOrderRequestType} from "../../types/request/data-order-request.type";
import { AwbHistoryRequestType } from "src/app/types/request/awb-histroy-request.type";
import { DownloadOrderRequsetType } from "src/app/types/request/download-order-request.type";

@Injectable({
  providedIn: 'root',
})

export class OrderService {
  constructor(private basService: BaseService) {
  }

  accumulationDataOrder(params: AccumulationDataOrderRequsetType) {
    return this.basService.getData(ACCUMULATION_DATA_ORDER, params, null);
  }

  dataOrder(params: DataOrderRequestType) {
    return this.basService.getData(DATA_ORDER, params, null);
  }
  detailDataOrder(id: number) {
    return this.basService.getData(`${DETAIL_DATA_ORDER}/${id}`, null);
  }
  awbHistory(params: AwbHistoryRequestType) {
    return this.basService.getData(AWB_HISTORY, params, null);
  }
  downloadOrder(params: DownloadOrderRequsetType) {
    return this.basService.getData(DOWNLOAD_ORDER, params, null);
  }
}
