import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { UserService } from './api/user.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Profile } from '../models/profile.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private destroy$ = new Subject<void>();

  // Update type for BehaviorSubject
  private profileDataSubject = new BehaviorSubject<Profile | null>(null);
  profileData$ = this.profileDataSubject.asObservable();

  constructor(
    private storageService: LocalStorageService,
    private userService: UserService
  ) {}

  getProfile(): void {
    this.userService
      .profile()
      .pipe(
        takeUntil(this.destroy$),
        tap((res) => {
          if (res.data.is_rajaongkir === true) {
            localStorage.setItem('role-user', 'raja-ongkir');
          } else {
            localStorage.setItem('role-user', 'open-api');
          }

          // Update the BehaviorSubject with the response data
          this.profileDataSubject.next(res.data);
        })
      )
      .subscribe();
  }

  // Correctly handle destroy$ cleanup
  destroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
