<div class="container p-2">
  <div class="d-flex flex-column align-items-center mt-3">
    <h5>Download Data Order</h5>
    <span>Kustomisasi data order yang ingin kamu download</span>
  </div>
  <div class="d-flex justify-content-between mt-4 px-4 col-12 text-bold" style="font-size: 16px;">
    <span class="col-3">Tanggal</span>
    <span class="col-3">Status</span>
    <span class="col-3">Metode Pembayaran</span>
    <span class="col-3">Ekspedisi</span>
  </div>
  <hr />
  <div class="d-flex justify-content-between mt-4 px-4 col-12 text-bold">
    <div class="col-3">
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="radio"
          id="flexRadioDefault1"
          checked
          [(ngModel)]="selectedValue"
          value="order"
          (change)="onRadioChange()"
        />
        <label class="form-check-label" for="flexRadioDefault1">
          Tanggal Order
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="radio"
          id="flexRadioDefault2"
          [(ngModel)]="selectedValue"
          value="update_status"
          (change)="onRadioChange()"
        />
        <label class="form-check-label" for="flexRadioDefault2">
          Tanggal Update Status
        </label>
      </div>
      <form class="row row-cols-sm-auto w-80">
        <div class="form-control d-flex flex-row" style="width: 80%">
          <input
            type="text"
            class="w-100"
            ngxDaterangepickerMd
            [(ngModel)]="selected"
            [ngModelOptions]="{ standalone: true }"
            [showCustomRangeLabel]="true"
            [keepCalendarOpeningWithRange]="true"
            [alwaysShowCalendars]="true"
            [ranges]="ranges"
            [linkedCalendars]="true"
            [isInvalidDate]="isInvalidDate"
            [showCancel]="true"
            opens="right"
            drops="down"
            [locale]="setlocale"
            (change)="onChangeDate($event)"
            style="border: none; font-size: 14px"
            readonly
          />
          <img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/calendar.svg"
            alt=""
          />
        </div>
      </form>
    </div>
    <div *ngIf="isOrder" class="col-3">
      <div class="form-check" *ngFor="let list of status; let i = index">
        <input
          class="form-check-input"
          type="checkbox"
          [value]="list.value"
          (change)="getStatus($event, list)"
          [id]="list.id"
          [checked]="list.checked"
        />
        <label class="form-check-label" [for]="list.id">
          {{ list.label }}
        </label>
      </div>
    </div>
    <div *ngIf="!isOrder" class="col-3">
      <div class="form-check" *ngFor="let list of status; let i = index">
        <input
          class="form-check-input"
          type="radio"
          name="radiostatus"
          [value]="list.value"
          (change)="getRadio(list.value)"
          [id]="list.id"
        />
        <label class="form-check-label" [for]="list.id">
          {{ list.label }}
        </label>
      </div>
    </div>
    <div class="col-3">
      <div class="form-check" *ngFor="let item of payment; let i = index">
        <input
        class="form-check-input"
        type="checkbox"
        [value]="item.value"
        [id]="item.id" 
        [checked]="item.checked"
        (change)="paymentMethod($event, item)"/>
        <label class="form-check-label" [for]="item.id">{{item.label}}</label>
      </div>
    </div>
    <div class="col-3">
      <div class="form-check" *ngFor="let list of ekspedisi; let i = index">
        <input
          class="form-check-input"
          type="checkbox"
          [value]="list.value"
          [id]="list.id"
          [checked]="list.checked"
          (change)="selectEkspedisi($event, list)"
        />
        <label class="form-check-label" [for]="list.id">
          {{ list.label }}
        </label>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-between col-12 mt-4 mb-2">
    <div
      *ngIf="progress > 0"
      class="d-flex flex-column justify-content-start col-8 px-4"
    >
      <span *ngIf="progress < 100; true">Sedang memuat File</span>
      <span *ngIf="progress === 100">File berhasil didownload</span>
      <nz-progress [nzPercent]="progress"></nz-progress>
    </div>
    <div
      class="d-flex gap-2 justify-content-end px-4"
      [ngClass]="progress > 0 ? 'col-4' : 'col-12'"
    >
      <button
        class="btn btn-outline-primary"
        style="height: 40px"
        (click)="handleClose()"
      >
        Batal
      </button>
      <button
        class="d-flex btn btn-primary text-white"
        style="height: 40px"
        [disabled]="downloaded"
        (click)="download()"
      >
        {{ txt_download }}
      </button>
    </div>
  </div>
</div>
