import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'idr',
})
export class IdrCurrencyPipe implements PipeTransform {
  transform(value: number): string {
    if (value >= 10000 && value <= 3000) {
      return `Rp ${value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    } else {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value);
    }
  }
}
