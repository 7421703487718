import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-register-success',
  templateUrl: './modal-register-success.component.html',
  styleUrls: ['./modal-register-success.component.scss'],
})
export class ModalRegisterSuccessComponent implements OnInit {
  constructor(private modal: NzModalRef) {}

  destroyModal(): void {
    this.modal.destroy();
  }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
}
