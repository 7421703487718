<div class="card">
        <div class="p-3 d-flex align-items-center">
            <button class=""
                style="border: none; background-color: transparent"
                (click)="back()">
                <img
                    src="https://storage.googleapis.com/komerce/assets/icons/back.svg"
                    alt=""
                    width="40px"
                />
            </button>
            <span
            class="text-black"
            style="font-size: 20px; font-weight: 600; margin-left: 15px"
            >Rincian Komisi</span
          >
        </div>
        <hr>
        <div class="p-3 d-flex justify-content-between flex-wrap">
            <div class="card-saldo d-flex flex-grow-0 align-items-center">
                <span class="p-4 ml-4" style="font-size: 18px;">
                        Saldo:
                </span>
                <span class="" style="color: #f95031; font-size: 18px; font-weight: 600; margin-left: -15px; margin-right: 15px;">
                        {{ (balance.commission | idr) || 0}}
                </span>
            </div>
            <div
                class="d-flex justify-content-end flex-row flex-grow-1 gap-2 align-items-center mt-2">
                <div class="d-flex align-items-center">
                    <div class="form-group has-search d-flex align-items-center">
                    <span class="form-control-feedback"><i class="icon-search-normal-1"></i></span>
                    <input [(ngModel)]="filterHistory.search" (ngModelChange)="onChangeSearch($event)" type="text" class="form-control" placeholder="Search">
                    </div>        
                </div>
                <div class="d-flex align-items-center">
                    <div ngbDropdown class="d-inline-block">
                    <button
                        type="button"
                        class="btn btn-primary btn-filter"
                        id="dropdownfilterorder"
                        ngbDropdownToggle
                    >
                        <i class="icon-filter-search text-white"></i>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownfilterorder">
                        <button ngbDropdownItem disabled class="text-black">
                        <b>Filter</b>
                        </button>
                        <div ngbDropdown placement="left" class="d-inline-block w-100">
                        <button
                            type="button"
                            class="btn w-100"
                            id="dropdownstatus"
                            style="text-align: start;"
                            ngbDropdownToggle
                        >
                            Jenis Transaksi
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownstatus">
                            <button class="btn w-100" style="text-align: start;">
                            <div class="form-check" *ngFor="
                            let list of status;
                            let i = index
                            ">
                                <input class="form-check-input"
                                type="checkbox"
                                (value)="list.value"
                                (id)="list.id"
                                (change)="getStatus($event, list)"
                                [checked]="list.checked">
                                <label class="form-check-label" [for]="list.id" style="font-size: 12px; text-wrap: nowrap;">
                                {{list.label}}
                                </label>
                            </div>
                            </button>
                        </div>
                        <button
                            type="button"
                            class="btn w-100 ngx-daterangepicker-action"
                            id="dropdownstatus"
                            style="text-align: start;"
                            (click)="openDatepicker()"
                            >
                            Periode
                        </button>
                        <div id="dropdownstatus" class="" container="body">
                            <input
                            type="text"
                            class="btn w-100"
                            ngxDaterangepickerMd
                            [(ngModel)]="selected"
                            [ngModelOptions]="{standalone: true}"
                            [showCustomRangeLabel]="false"
                            [keepCalendarOpeningWithRange]="true"
                            [alwaysShowCalendars]="true"
                            [ranges]="ranges"
                            [linkedCalendars]="true"
                            [isInvalidDate]="isInvalidDate"
                            [showCancel]="true"
                            opens="center"
                            drops="down"
                            [locale]="setlocale"
                            (change)="onChangeDate($event)"
                            style="border: none; font-size: 14px; display: none;"
                            #datePickerInput
                            container="body"
                            />
                        </div>
                        <div>
                          <button class="btn w-100" style="text-align: start; color: #f95031;" (click)="resetFilter()">
                            Reset Filter</button>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column table-wrapper mt-3 w-100 p-4" style="overflow-x: auto; z-index: 0;">
            <table class="table table-hover table-responsive ">
              <thead class="sticky-top" style="background-color: rgb(245, 245, 245);">
              <tr style="font-size: 16px;">
                <th scope="col">Tanggal</th>
                <th scope="col">Uraian Mutasi</th>
                <th scope="col">No Order</th>
                <th scope="col">Nominal Komisi</th>
                <th scope="col">Komisi Akhir</th>
              </tr>
              </thead>
              <tbody class="tabb-body" style="overflow:auto;" *ngIf="HistoryList !== null">
                <tr *ngFor="let items of HistoryList, let i = index">
                  <td>
                    <div class="d-flex flex-column">
                      <div>{{items.transaction_date | date : "dd MMMM yyyy" : 'id-ID' || '-'}}</div>
                      <div class="text-muted">{{items.transaction_date.slice(10, -3) || '-'}} WIB</div>
                    </div>
                  </td>
                  <td>
                    <div class="text-bold" [ngClass]="items.transaction_type === 'order_commission' ? 'success ' : 'failed'">{{transactionType(items.transaction_type) || '-'}}</div>
                  </td>
                  <td>
                    <div>{{items.order_no || '-'}}</div>
                  </td>
                  <td>
                    <div [ngClass]="items.transaction_type === 'withdrawal' || items.transaction_type === 'order_returned' ? 'text-danger' : ' text-success'">
                      <span *ngIf="items.transaction_type === 'withdrawal' || items.transaction_type === 'order_returned'">
                        - {{items.nominal | idr}}
                      </span>
                      <span *ngIf="items.transaction_type !== 'withdrawal' && items.transaction_type !== 'order_returned'">
                        + {{items.nominal | idr}}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div>{{items.commission | idr}}</div>
                  </td>
                </tr>
            </tbody>
            <tbody *ngIf="HistoryList === null">
              <tr>
                <td colspan="6" class="empty">
                  Tidak ada data yang ditampilkan
                </td>
              </tr>
            </tbody>
            </table>
          </div>
</div>