import {Injectable} from "@angular/core";
import {BaseService} from "../base.service";
import {BANKLIST, BANKSTORE, BANKUPDATE, COMISSION_DETAIL, COMISSION_HISTORY} from "../../constants/endpoint.const";
import {BankRequestType} from "../../types/request/bank-request.type";
import { BalanceHistoryRequestType } from "src/app/types/request/balanace-history-request";

@Injectable({
  providedIn: 'root',
})

export class ComissionService {
  constructor(
    private baseService: BaseService
  ) {
  }

  commisionHistory(params: BalanceHistoryRequestType) {
    return this.baseService.getData(COMISSION_HISTORY, params, null);
  }
  commisionDetail(params: BalanceHistoryRequestType) {
    return this.baseService.getData(COMISSION_DETAIL, params, null);
  }

}
