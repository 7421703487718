import { Component } from '@angular/core';

@Component({
  selector: 'komerce-searchbar',
  templateUrl: './komerce-searchbar.component.html',
  styleUrls: ['./komerce-searchbar.component.scss']
})
export class KomerceSearchbarComponent {

}
