import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {ToastComponent} from "./components/toast/toast.component";
import { OtpInputComponent } from './components/otp-input/otp-input.component';
import { CounterDirective } from 'src/app/directives/timer.directive';
import { OtpPipe } from 'src/app/pipes/otp.pipe';
import { NumberOnly } from 'src/app/directives/number-only.directive';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { BlokReqWDComponent } from './components/modal-blocked-request-wd/blocked-req-wd.component';


@NgModule({
  declarations: [
    ToastComponent,
    OtpInputComponent,
    OtpPipe,
    CounterDirective,
    NumberOnly,
    MaintenanceComponent,
    BlokReqWDComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
  ],
  exports: [
    CommonModule,
    ToastComponent,
    OtpInputComponent,
    CounterDirective,
    OtpPipe,
    NumberOnly,
  ]
})
export class SharedModule { }
