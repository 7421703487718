<div class="card p-4 d-flex flex-column mt-2">
  <div class="py-1 title">
    Billing
    <hr />
  </div>
  <nz-card class="col-10 col-sm-12 col-lg-5 p-3 border rounded" nzHoverable>
    <div class="d-flex justify-content-between align-items-start">
      <div class="d-flex justify-content-start gap-2">
        <div
          class="d-flex rounded justify-content-center"
          style="
            background-color: #fff2e2;
            padding: 5px;
            height: 30px;
            width: 30px;
          "
        >
          <img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/profile-tick.svg"
            alt="member"
            width="25px"
          />
        </div>
        <div class="d-flex flex-column">
          Membership Package
          <div class="title mt-3 text-capitalize">
            {{ statusBillings.package_name || "Starter" }}
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-end upgrade align-items-center gap-2"
        (click)="handleUpgrade()"
      >
        <img
          src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/arrow-up.svg"
          alt="upgrade"
        />
        Upgrade
      </div>
    </div>
    <div
      [@collapseExpand]="activeDetail ? 'expanded' : 'collapsed'"
      class="mb-4"
    >
      <div class="py-2 mt-2 border-dash"></div>
      <div class="d-flex justify-content-between">
        <div>
          Limit HIT API {{ statusBillings.max_api_hits || "0" }}/day
          (RajaOngkir)
        </div>
        <div>
          <strong
            >{{ statusBillings.api_hits || "0" }}/{{
              statusBillings.max_api_hits || "0"
            }}</strong
          >
        </div>
      </div>
      <div
        *ngIf="statusBillings.package_name !== 'starter'"
        class="d-flex justify-content-between"
      >
        <div>Check Domestics Shipping Cost (25 Expeditions)</div>
        <div><strong>25/25</strong></div>
      </div>
      <div
        *ngIf="statusBillings.package_name !== 'starter'"
        class="d-flex justify-content-between"
      >
        <div>Check International Shipping Cost (REY, TIKI, EXPEDITO)</div>
        <div><strong>3/3</strong></div>
      </div>
      <div
        *ngIf="statusBillings.package_name === 'starter'"
        class="d-flex justify-content-between"
      >
        <div>Cek Shipping Cost Domestics : JNE, POS, TIKI</div>
        <div><strong>3/3</strong></div>
      </div>
      <div
        *ngIf="statusBillings.package_name !== 'starter'"
        class="d-flex justify-content-between"
      >
        <div>Check Receipt</div>
        <div *ngIf="statusBillings.package_name === 'pro'">
          <strong>Yes</strong>
        </div>
        <div *ngIf="statusBillings.package_name === 'enterprise'">
          <strong>4/4</strong>
        </div>
      </div>
      <div
        *ngIf="statusBillings.package_name === 'enterprise'"
        class="d-flex justify-content-between"
      >
        <div>Free SHIPPING API</div>
        <div>
          <strong>Yes</strong>
        </div>
      </div>
    </div>
    <div
      class="text-center detail-bg py-1"
      (click)="activeDetail = !activeDetail"
    >
      Detail
      <i
        class="text-bold mx-1"
        [ngClass]="activeDetail ? 'k-arrow-up-2' : 'k-arrow-down-1'"
      ></i>
    </div>
  </nz-card>
  <div class="col-12 mt-5">
    <h6>Membership History</h6>
    <cdk-virtual-scroll-viewport
      itemSize="20"
      class="scroll-viewport"
      (scrolledIndexChange)="onScroll()"
      style="height: 500px; width: 100%"
    >
      <nz-table
        #headerTable
        [nzData]="listOfData"
        [nzShowPagination]="false"
        class="mt-3"
        [nzLoading]="loading"
      >
        <thead>
          <tr class="membership">
            @for (column of listOfColumns; track column) {
            <th>
              <div class="d-flex justify-content-between">
                <span>
                  {{ column.name }}
                </span>
                <img
                  *ngIf="column.name === 'Date'"
                  src="https://storage.googleapis.com/komerce/assets/icons/arrow-filter.svg"
                  alt="arrow"
                  (click)="shortHistoryBillings()"
                />
              </div>
            </th>
            }
          </tr>
        </thead>
        <tbody>
          <ng-container *cdkVirtualFor="let data of listOfData">
            <tr>
              <td>
                <span
                  class="d-flex align-items-center justify-content-center"
                  [ngClass]="styleStatus(data.status)"
                  >{{ handleStatus(data.status) }}</span
                >
              </td>
              <td>
                {{ data.created_at | date : "dd MMMM yyyy" || "-" }}
              </td>
              <td>
                {{ data.expired_date | date : "dd MMMM yyyy" || "-" }}
              </td>
              <td>
                <img [src]="iconPackage(data.package)" alt="package" /> •
                {{ data.package_duration }}
                <span *ngIf="data.package_duration > 1">Months</span>
                <span *ngIf="data.package_duration === 1">Month</span>
              </td>
              <td>{{ data.amount | idr }}</td>
            </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
