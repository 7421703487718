<div class="d-flex flex-column gap-3 justify-content-center align-items-center">
    <div class="d-flex mb-4 flex-column justify-content-center gap-3 ps-5 pe-5 align-items-center" style="width: 500px;">
      <div class="overflow-hidden d-flex align-items-center justify-content-center mt-5">
        <img height="100px" src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png" alt="">
      </div>
      <div>
        <div class="desc text-center mt-2">
         <p>Maaf, kamu tidak bisa melakukan penarikan saldo dikarenakan kamu masih memiliki antrian penarikan yang belum di setujui.</p>
        </div>
        <button (click)="handleClose($event)" class="btn btn-primary w-100 text-white mt-4">Ok</button>
      </div>
    </div>
</div>
  