import {
    Component,
    OnInit,
  } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

  @Component({
    selector: 'block-req-wd',
    templateUrl: './blocked-req-wd.component.html',
    styleUrls: ['./blocked-req-wd.component.scss'],
  })
  export class BlokReqWDComponent implements OnInit {

    constructor(
        private activeModal: NgbActiveModal,
        ) { }
      ngOnInit(): void {
      }

      handleClose(e: any): void{
        this.activeModal.dismiss()
        // window.location.reload()
    }
    
}
