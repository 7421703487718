import { Component, ElementRef, Input, NgZone, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { trigger, transition, animate, style } from '@angular/animations';
import { OrderService } from 'src/app/services/api/order.service';
import { awbHistoryModel } from 'src/app/models/awb-history.model';
import { Subject, takeUntil, } from 'rxjs';
import { webSocket } from 'rxjs/webSocket'; 
import { WebSocketService } from 'src/app/services/web-socket.service';
import { TicketService } from 'src/app/services/api/ticket.service';
import { CHAT_TICKET } from 'src/app/constants/endpoint.const';

@Component({
  selector: 'app-message-ticket',
  templateUrl: './message-ticket.component.html',
  styleUrls: ['./message-ticket.component.scss'],
  animations: [
    trigger('zoomBigMotion', [
      transition(':enter', [
        style({ transform: 'scale(0)' }),
        animate('0.4s cubic-bezier(.8, -0.6, 0.2, 1.5)', style({ transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        animate('0.2s', style({ transform: 'scale(0)' }))
      ])
    ])
  ]
})

export class MessageTicketComponent {
    destroy$ = new Subject();
    items = [];
    id!: string;
    fileUpload: File[] = [];
    fileName: any [] = [];
    @ViewChild('fileupload') fileUploadRef!: ElementRef;
    previewImage: string | undefined = '';
    previewVisible = false;
    awbHistory: awbHistoryModel[] = [];
    @Input()shipping: string = '';
    @Input()awb: string = ''; 
    @Input()shipping_logo: string = ''; 
    @Input()total_transaction: number = 0; 
    chatContent: any[] = [];
    messages: string = '';
    isVisible = false;
    indexImage!: number;
    isDisable = true;
    isImage = true;
    isExcel = false;

    constructor(
      private activeRoute: ActivatedRoute,
      private toastService: ToastService,
      private orderService: OrderService,
      private ticketService: TicketService,
      private zone: NgZone,
    ){}
    ngOnInit() {
      this.activeRoute.params.subscribe((params) => {
        this.id = params['id'];
      });
      this.getAwbHistory()
      this.getChatTicket()
    }
    showModal(i: any): void {
      this.indexImage = i
      this.isVisible = true;
    }

    handleCancel(): void {
      this.isVisible = false;
    }

    getChatTicket() {
      this.ticketService
      .chatTicket(this.id)
      .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            this.chatContent = res.data
          },
          () => {}
        );
    } 

    disableSend(v: any) {
      this.isDisable = !v.trim().length
    }

    modifyAttachmentURL(v: string): string {
      if (v && v.includes('/attachment/prod/')) {
        return v.replace('/attachment/prod/', '');
      } else if (v && v.includes('/attachment/dev/')) {
        return v.replace('/attachment/dev/', '');
      }
      return v;
    }

    isImageFile(file: string): boolean {
      const imageExtensions = ['.jpg', '.png', '.jpeg'];
      const extension = file.substring(file.lastIndexOf('.')).toLowerCase();
      return imageExtensions.includes(extension);
    }

    triggerFileInput() {
      this.fileUploadRef.nativeElement.click();
    }

    handleFileUpload(event: any) {
      const files: File[] = event.target.files;
    
      if (this.fileUpload.length + files.length > 3) {
        this.toastService.show(null, 'Anda hanya bisa upload 3 file', 'danger', {
          classname: 'bg-danger',
          delay: 3000,
        });
        return;
      }
      
      const allowedFormats = ['png', 'jpg', 'jpeg', 'pdf', 'xlsx', 'xls'];
    
      for (let file of files) {
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        if (!allowedFormats.includes(fileExtension as string)) {
          this.toastService.show(null, `Format file '.${fileExtension}' tidak diperbolehkan.`, 'danger', {
            classname: 'bg-danger',
            delay: 3000,
          });
          return;
        }
        this.fileUpload.push(file);
        
        const reader = new FileReader();
        
        reader.onload = (e: any) => {
          const fileExtension = file.name.split('.').pop();
          let url: string;

          switch (fileExtension) {
              case 'pdf':
                  url = 'https://storage.googleapis.com/komerce/assets/icons/file-icons/pdf.png';
                  break;
              case 'xls':
              case 'xlsx':
                  url = 'https://storage.googleapis.com/komerce/assets/icons/xls.png';
                  break;
              default:
                  url = '';
                  break;
          }
          this.fileName.push({ url: (url !== '') ? url : e.target.result, name: file.name });
      };
        
        reader.readAsDataURL(file);
        if (file.name.split('.').pop()?.toLowerCase() === 'xlsx' || file.name.split('.').pop()?.toLowerCase() === 'xls') {
          this.isExcel = true
        } 
      }
    }

    sendMessages() {
      const formdata = new FormData();
        formdata.append("message", this.messages);
        this.fileUpload.forEach((file, index) => {
          formdata.append("attachments", file);
        });

      this.ticketService
      .sendMessages(this.id, formdata)
      .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            this.messages = ''
            this.fileUpload = []
            this.getChatTicket()
          },
          () => {}
        )
      }

    deleteFile(index: number): void {
      this.fileUpload.splice(index, 1);
      this.fileName.splice(index, 1);
    }

    getAwbHistory() {
      const params = {
        shipping: this.shipping,
        airway_bill: this.awb
      }
      this.orderService
      .awbHistory(params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.awbHistory = res.data.history
        },
      )
    }

    iconAwb(value: string) {
      let result = ''
      if (value === 'Pickup') {
        result = 'https://storage.googleapis.com/komerce/assets/icons/resi-pickup.svg'
      } else if (value === 'Process') {
        result = 'https://storage.googleapis.com/komerce/assets/icons/resi-kirim.svg'
      } else if (value === 'Problem') {
        result = 'https://storage.googleapis.com/komerce/assets/icons/resi-problem.svg'
      } else if (value === 'Delivered') {
        result = 'https://storage.googleapis.com/komerce/assets/icons/resi-terima.svg'
      } else if (value === 'Retur') {
        result = 'https://storage.googleapis.com/komerce/assets/icons/resi-retur.svg'
      } else if (value === 'Delivery') {
        result = 'https://storage.googleapis.com/komerce/assets/icons/resi-delivery.svg'
      }
      return result
    }
}
