import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SHOW_PER_PAGE, PAGINATION, SHOW_PER_PAGE_DASHBOARD } from '../../../../../../../constants/pagination.const';
import { PaginationModel } from 'src/app/models/pagination.model';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginiationComponent implements OnInit {
  @Input() pagination! : PaginationModel
  showPerpageDashboard = SHOW_PER_PAGE_DASHBOARD
  perPageDashboard = SHOW_PER_PAGE_DASHBOARD[0]

  @Output() getQueryPagination = new EventEmitter()

  constructor() { }

  ngOnInit() {
  }

  onChangePerpage(e: any){
    this.perPageDashboard = +e.target.value
    this.pagination.PAGE = 1

    // this.pagination?.PER_PAGE = e.target.value
  }

  onHandlePage(e: any){
    this.getQueryPagination.emit(e)
    this.pagination.PAGE = e;
  }
  getPaginationRange(): number[] {
    const totalPages = this.pagination?.PAGE_COUNT;
    const currentPage = this.pagination?.PAGE;
    let pagesToShow = 5;
    const halfPagesToShow = Math.floor(pagesToShow / 2);
    let startPage: number, endPage: number;
  
    if (totalPages <= pagesToShow) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage - halfPagesToShow <= 0) {
      startPage = 1;
      endPage = pagesToShow;
    } else if (currentPage + halfPagesToShow >= totalPages) {
      startPage = totalPages - pagesToShow + 1;
      endPage = totalPages;
    } else {
      startPage = currentPage - halfPagesToShow;
      endPage = currentPage + halfPagesToShow;
    }
  
    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  }

}
