import {Injectable} from "@angular/core";
import {BaseService} from "../base.service";
import {BANKLIST, BANKSTORE, BANKUPDATE, BANKLISTAV} from "../../constants/endpoint.const";
import {BankRequestType} from "../../types/request/bank-request.type";
import { BankStoreRequestType } from "src/app/types/request/bank-store-request.type";
import { BankUpdateRequestType } from "src/app/types/request/bank-update-request.type";

@Injectable({
  providedIn: 'root',
})

export class BankService {
  constructor(
    private baseService: BaseService
  ) {
  }

  bankList() {
    return this.baseService.getData(BANKLIST, null, null);
  }
  bankListAvail() {
    return this.baseService.getData(BANKLISTAV, null, null);
  }

  bankStore(body: BankStoreRequestType) {
    return this.baseService.postData(BANKSTORE, body, null, null);
  }

  bankUpdate(body: BankUpdateRequestType) {
    return this.baseService.putData(BANKUPDATE, body, null, null);
  }
}
