import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-billings',
  templateUrl: './billings.component.html',
  styleUrls: ['./billings.component.scss'],
})
export class BillingsComponent implements OnInit {
  constructor() {}

  destroyModal(): void {}
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
}
