<div
    class="d-flex flex-column gap-3 justify-content-center align-items-center"
  >
    <div
      class="d-flex w-100 ps-4 pe-4 flex-column gap-3 justify-content-center mt-4"
    >
        <div class="text-center">
            <img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png" alt="" width="30%">
        </div>
      <div class="title text-center">Buat PIN</div>

      <div class="w-100 text-center">
        <span>{{ contentText }}</span>
      </div>
      <div class="w-100 mb-3 mt-2 d-flex justify-content-center gap-3">
        <button (click)="handleClose($event)" class="btn btn-outline-primary w-50">Batal</button>
        <button (click)="handleConfirm($event)" class="btn btn-primary w-50">
          <div class="ps-3 pe-3" style="color: white">Buat PIN</div>
        </button>
      </div>
    </div>
  </div>