<div class="card">
  <div class="card-header">Keuangan</div>
  <div class="card-body">
    <div>
      <ul class="nav gap-3">
        <li class="nav-item">
          <a class="nav-link" routerLink="/pages/keuangan/saldo">Riwayat Saldo</a>
        </li>
        <li class="nav-item active">
          <a class="nav-link active" aria-current="page">Penarikan Komisi</a>
        </li>
      </ul>
    </div>
    <div class="mt-5 mb-3 row">
      <div class="col-md-4 col">
        <div class="card shadow">
          <div class="p-3">
            <div class="d-flex align-items-center gap-1">
              <div class="card-title d-flex align-self-center">Komisi</div>
              <div class="d-flex align-self-center"
                   placement="top"
                   ngbPopover="Komisi kamu saat ini"
                   triggers="mouseenter:mouseleave"
              ><i class="icon-info-circle align-self-center"></i></div>
            </div>
            <div class="nominal">
              <b>{{balance.commission | idr }}</b>
            </div>
          </div>
          <div class="bg-footer-card p-3 d-flex flex-row justify-content-center">
            <button
            *ngIf="pinStatus === true && bankList !== null"
            class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
            style="border: none; background-color: transparent"
            (click)="handleTarikSaldo($event)"
          >
            <div><i class="icon icon-receive-square"></i></div>
            <div class="d-flex text-card-button" >Tarik Saldo</div>
          </button>
          <button
          *ngIf="pinStatus === true && bankList === null"
            class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
            style="border: none; background-color: transparent"
            (click)="handleNoRek($event)"
          >
            <div><i class="icon icon-receive-square"></i></div>
            <div class="d-flex text-card-button" >Tarik Saldo</div>
          </button>
          <button
          *ngIf="pinStatus === false && bankList !==  null"
            class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
            style="border: none; background-color: transparent"
            (click)="handleNoPIN($event)"
          >
            <div><i class="icon icon-receive-square"></i></div>
            <div class="d-flex text-card-button" >Tarik Saldo</div>
          </button>
          <button
          *ngIf="pinStatus === false && bankList ===  null"
            class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center"
            style="border: none; background-color: transparent"
            (click)="handleNoPIN($event)"
          >
            <div><i class="icon icon-receive-square"></i></div>
            <div class="d-flex text-card-button" >Tarik Saldo</div>
          </button>
          <div class="d-flex px-3 flex-row gap-2 align-items-center justify-content-center" (click)="handleDetail($event)" style="cursor: pointer;">
            <div > <i class="icon icon-document-text"></i> </div>
            <div class="d-flex text-card-button">Detail</div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="mt-3">
      <app-table-riwayat-penarikan-saldo></app-table-riwayat-penarikan-saldo>
    </div>
  </div>
</div>
