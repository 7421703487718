import {
    Component,
    OnInit,
  } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

  @Component({
    selector: 'maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
  })
  export class MaintenanceComponent implements OnInit {

    constructor(
        private activeModal: NgbActiveModal,
        ) { }
      ngOnInit(): void {
      }

      handleClose(e: any): void{
        this.activeModal.dismiss()
    }
}
