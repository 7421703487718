<div class="card h-100 p-3 d-flex flex-column gap-3">
    <div class="d-flex flex-row justify-content-beetwen align-items-center">
        <div class="title d-flex flex-grow-0 justify-content-start">Rekening Bank</div>
        <div class="d-flex flex-grow-1 justify-content-end">
            <button class="btn btn-primary text-white" (click)="openAddRek(content)" [disabled]="addDisable">Tambah Rekening</button>
        </div>
    </div>
    <div class="divider"></div>
    <div *ngIf="bankList === null" class="d-flex flex-column justify-content-center align-items-center">
      <img class="mt-4" src="https://storage.googleapis.com/komerce/assets/illustration/BG-Rekening-Bank.svg" alt="" width="50%">
      <h2 class="mt-4">Belum Ada Rekening Bank Tersimpan</h2>
      <p>Yuk, tambah rekening bank kamu biar lebih mudah saat tarik saldo Kompay kamu</p>
    </div>
    <div *ngIf="bankList !== null">
        <div *ngFor="let items of bankList; let i = index">
            <div class="form-control mb-4">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex justify-content-start p-2">
                        <strong>Rekening {{items.id}}</strong>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button *ngIf="items.isDisable === true" class="btn btn-outline-primary" (click)="edit(i)">
                            Edit Rekening
                        </button>
                        <button *ngIf="items.isDisable === false" class="btn btn-outline-primary" (click)="batal(i)">
                            Batal
                        </button>
                        <button *ngIf="items.isDisable === false" class="btn btn-primary text-white ms-2" [disabled]="isDisSave" (click)="simpan(i)">
                            Simpan
                        </button>
                    </div>
                </div>
                <hr>
                <div class="d-flex flex-column gap-2 p-2">
                    <div class="d-flex align-items-center">
                        <div style="width: 200px;">
                            <span style="font-size: 14px;">Nama Bank</span><span class="text-danger" *ngIf="items.isDisable === false">*</span>
                        </div>
                        <div class="w-100">
                            <input
                                id="typeahead-template"
                                type="search"
                                class="form-control bank-name"
                                [ngModel]="items.bank_name"
                                [ngbTypeahead]="search"
                                #inputValue
                                [inputFormatter]="formatter"
                                [resultFormatter]="formatter"
                                selectOnExact
                                [placeholder]="items.bank_name"
                                [disabled]="items.isDisable"
                                required 
                                (ngModelChange)="onEditBankName($event, i)"
                            />
                            <small class="text-danger" *ngIf="items.isEmptyBank">
                                <img src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg" alt="" width="12px">
                                Bank Tidak Boleh Kosong</small>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <div style="width: 200px;">
                            <span style="font-size: 14px;">No Rekening</span><span class="text-danger" *ngIf="items.isDisable === false">*</span>
                        </div>
                        <div class="w-100">
                            <input type="text" class="form-control" [ngModel]="items.bank_number" [disabled]="items.isDisable" (ngModelChange)="onEditBankNumber($event, i)" numberOnly>
                            <small class="text-danger" *ngIf="items.isEmptyRek">
                                <img src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg" alt="" width="12px">
                                Rekening Tidak Boleh Kosong</small>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <div style="width: 200px;">
                            <span style="font-size: 14px;">Nama</span><span class="text-danger" *ngIf="items.isDisable === false" >*</span>
                        </div>
                        <div class="w-100">
                            <input type="text" class="form-control" [ngModel]="items.bank_owner" [disabled]="items.isDisable" (ngModelChange)="onEditBankOwner($event, i)">
                            <small class="text-danger" *ngIf="items.isEmptyOwn">
                                <img src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg" alt="" width="12px">
                                Nama Tidak Boleh Kosong</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <ng-template #content let-offcanvas>
    <div class="d-flex flex-row align-items-center justify-content-beetwen">
        <div class="offcanvas-header d-flex flex-column flex-grow-0 justify-content-start align-items-start">
            <h4 class="offcanvas-title">Tambah Rekening</h4>
            <p>Rekening untuk transaksi di Komerce</p>
        </div>
        <div class="d-flex flex-grow-1 justify-content-end me-4">
            <button type="button" class="btn btn-tutup" aria-label="Close" (click)="closeAddRek(content)">
                <img src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/close.svg" alt="" width="28px">
            </button>
        </div>
    </div>
    <div class="divider"></div>
    <form [formGroup]="formGroup" action="" class="offcanvas-body d-flex flex-column" style="align-content: space-between;" (keydown.enter)="$event.preventDefault()">
        <div class="offcanvas-body d-flex flex-column" style="align-content: space-between;" ref="offcanvas">
            <div class="form flex-grow-0" style="align-content: flex-start;">
                <div class="bank">
                    <label for="bank-name" class="form-label">Nama Bank</label>
                    <input
                        id="typeahead-template"
                        [(ngModel)]="bank"
                        type="search"
                        class="form-control"
                        [ngbTypeahead]="search"
                        #inputValue
                        [inputFormatter]="formatter"
                        [resultFormatter]="formatter"
                        selectOnExact
                        placeholder="Masukkan Bank Anda"
                        required 
                        formControlName="bank"
                        (ngModelChange)="onSearchBank($event)"
                        (keydown.enter)="$event.preventDefault()"
                    />
                    <small class="text-danger" *ngIf="isNotFound">*Bank yang kamu cari tidak ada</small>
                    <small class="text-danger" *ngIf="isEmptyBank">
                        <img src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg" alt="" width="12px">
                        Pilih Bank terlebih dahulu ya</small>
                </div>
                <div class="mt-4">
                    <label for="Rekening" class="form-label">Rekening</label>
                    <input
                        type="text"
                        class="form-control"
                        id="Rekening"
                        placeholder="Masukkan Nomor Rekening"
                        [(ngModel)]="rekening"
                        (ngModelChange)="onAddRek($event)"
                        required
                        (keydown.enter)="$event.preventDefault()"
                        formControlName="rekening"
                        numberOnly>
                    <small class="text-danger" *ngIf="isEmptyRek">
                        <img src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg" alt="" width="12px">
                        Rekening Tidak Boleh Kosong</small>
                </div>
                <div class="mt-4">
                    <label for="Nama" class="form-label">Nama</label>
                    <input
                        type="text"
                        class="form-control"
                        id="Nama"
                        placeholder="Masukkan Nama Anda"
                        [(ngModel)]="owner"
                        (ngModelChange)="onAddOwner($event)"
                        required
                        (keydown.enter)="$event.preventDefault()"
                        formControlName="nama">
                    <small class="text-danger" *ngIf="isEmptyOwn">
                        <img src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg" alt="" width="12px">
                        Nama Tidak Boleh Kosong</small>
                </div>
            </div>
            <div class="d-flex flex-grow-1 justify-content-end" style="align-content: flex-end; flex-wrap: wrap;">
                <button type="submit" class="btn btn-primary text-white" (click)="validateAdd()" [disabled]="formGroup.invalid">Simpan</button>
            </div>
        </div>
    </form>
</ng-template>
