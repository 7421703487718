import { NgModule } from '@angular/core';
import {
  NgbAlertModule,
  NgbDatepickerModule,
  NgbTooltip,
  NgbTooltipModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, JsonPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalPinWDComponent } from './components/modal-pin-verification/modal-pin-verification.component';
import { ModalTarikSaldoComponent } from './components/modal-tarik-saldo/modal-tarik-saldo.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { BuatRekeningComponent } from './components/modal-buat-rekening/buat-rek.component';
import { BuatPinComponent } from './components/modal-buat-pin/buat-pin.component';
import { ModalTarikKomisiComponent } from './components/modal-tarik-komisi/modal-tarik-kom.component';

@NgModule({
  declarations: [
    ModalPinWDComponent,
    ModalTarikSaldoComponent,
    ModalTarikKomisiComponent,
    BuatRekeningComponent,
    BuatPinComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbDatepickerModule,
    NgbTooltip,
    NgbTooltipModule,
    NgbTypeaheadModule,
    SharedModule,
  ],
})
export class DashboardModule {}
