import { Component } from '@angular/core';

@Component({
  selector: 'komerce-shimmer',
  templateUrl: './komerce-shimmer.component.html',
  styleUrls: ['./komerce-shimmer.component.scss']
})
export class KomerceShimmerComponent {

}
