import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseService } from '../../services/base.service';
import {
  LOGIN,
  RECAPTCHA_TOKEN,
  RESEND_VERIFY,
} from 'src/app/constants/endpoint.const';
import { takeUntil, Subject, Subscription } from 'rxjs';
import { LocalStorageService } from '../../services/local-storage.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ModalRegisterSuccessComponent } from 'src/app/pages/register/modal-register-success/modal-register-success.component';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
})
export class AuthLayoutComponent implements OnInit {
  formGroup: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(50),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(32),
    ]),
  });

  isLoading: boolean = false;
  isShowPassword: boolean = false;
  destroy$ = new Subject();
  errorUser = '';
  errorRole = '';
  eyeOn =
    'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/eye.svg';
  eyeOff =
    'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/eye-slash.svg';

  errMessPass: string = '';
  isValidPass: boolean = true;
  validEmail: boolean = true;
  failedLogin: boolean = false;
  profileData!: any;

  constructor(
    private BaseService: BaseService,
    private renderer: Renderer2,
    private localStorageService: LocalStorageService,
    private router: Router,
    private message: NzMessageService,
    private modalService: NzModalService
  ) {}
  ngOnInit(): void {
    this.addScriptToHead(
      `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_TOKEN}`
    );
  }

  addScriptToHead(src: string): void {
    const script = this.renderer.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    script.async = true;
    this.renderer.appendChild(document.head, script);
  }

  disableEnterKey(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  validateEmail() {
    const value = this.formGroup.get('email')?.value || '';
    const regex = /^[a-zA-Z0-9+@._-]+$/g;

    if (value === '') {
      this.validEmail = false;
      this.errorUser = 'Email harus diisi';
    } else {
      if (value.length < 10) {
        this.errorUser = 'Minimal 10 karakter';
        this.validEmail = false;
      } else if (value.length > 50) {
        this.errorUser = 'Maksimal 50 karakter';
        this.validEmail = false;
      } else if (!regex.test(value)) {
        this.validEmail = false;
        this.errorUser = 'Email harus berupa alamat surel yang benar';
      } else {
        const atIndex = value.indexOf('@');
        const lastDotIndex = value.lastIndexOf('.');
        const dotBeforeAt = value.substring(0, atIndex).includes('.');
        const dotAfterAt = value.substring(atIndex + 1).includes('.');

        if (
          atIndex === -1 ||
          lastDotIndex === -1 ||
          lastDotIndex === value.length - 1 ||
          value[atIndex + 1] === '.' ||
          !dotAfterAt
        ) {
          this.errorUser = 'Email harus berupa alamat surel yang benar';
          this.validEmail = false;
        } else {
          this.validEmail = true;
          this.errorUser = '';
        }
      }
    }
  }

  validatePassword() {
    const value = this.formGroup.get('password')?.value || '';
    const regex = /^[a-zA-Z0-9`~!()\-_+={}\[\]:;'.<>\/]+$/g;

    if (value.length === 0) {
      this.errMessPass = 'Password harus diisi';
      this.isValidPass = false;
    } else if (value.length < 8) {
      this.errMessPass = 'Minimal 8 karakter';
      this.isValidPass = false;
    } else if (!regex.test(value)) {
      this.isValidPass = false;
      this.errMessPass = 'Terdapat karakter yang tidak didukung';
    } else {
      this.errMessPass = '';
      this.isValidPass = true;
    }
  }

  setRegex(event: any) {
    const regex = /^[a-zA-Z0-9+@._-]+$/g;
    return regex.test(event.key);
  }

  setRegexPassword(event: any) {
    const regex = /^[a-zA-Z0-9`~!()\-_+={}\[\]:;'.<>\/]+$/g;
    return regex.test(event.key);
  }

  showPasswordClick(): void {
    this.isShowPassword = !this.isShowPassword;
  }

  get form() {
    return {
      formGroup: this.formGroup,
      email: this.formGroup.get('email'),
      password: this.formGroup.get('password'),
    };
  }

  async getCaptchaToken(event: Event) {
    event.preventDefault();

    grecaptcha.enterprise.ready(async () => {
      try {
        const token = await grecaptcha.enterprise.execute(
          `${RECAPTCHA_TOKEN}`,
          { action: 'REGISTER_RAJAONGKIR' }
        );
        this.handleLogin(token);
      } catch (error) {
        console.error('Error executing reCAPTCHA:', error);
        this.message.create('error', 'Error executing reCAPTCHA');
      }
    });
  }

  resendVerif(event: Event) {
    event.preventDefault();

    grecaptcha.enterprise.ready(async () => {
      try {
        const token = await grecaptcha.enterprise.execute(
          `${RECAPTCHA_TOKEN}`,
          { action: 'RESEND_ACTIVATION' }
        );

        const params = {
          email: this.formGroup.get('email')?.value,
          recaptcha_token: token,
        };

        this.BaseService.postData(RESEND_VERIFY, params)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (response) => {
              this.modalService.create({
                nzContent: ModalRegisterSuccessComponent,
                nzFooter: null,
              });
            },
            (err) => {
              this.message.create(
                'error',
                'Terjadi kesalahan. Silahkan coba lagi nanti'
              );
            }
          );
      } catch (error) {
        console.error('Error executing reCAPTCHA:', error);
        this.message.create('error', 'Error executing reCAPTCHA');
      }
    });
  }

  handleLogin(e: string) {
    if (this.formGroup.get('password')?.value.length >= 6) {
      const params: object = {
        email: this.formGroup.get('email')?.value,
        password: this.formGroup.get('password')?.value,
        recaptcha_token: e,
        fcm_token: environment.fcm_token,
      };
      this.BaseService.postData(LOGIN, params)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response) => {
            const { data } = response;
            localStorage.setItem('auth-token', data.access_token);
            localStorage.setItem('refresh-token', data.refresh_token);
            this.localStorageService.saveUser(data.name);

            if (data.is_rajaongkir === true) {
              this.router.navigate(['/pages/billings']);
            } else {
              this.router.navigate(['/pages/dashboard']);
            }
          },
          (err) => {
            this.message.create(
              'error',
              'Terjadi kesalahan. Silahkan coba lagi nanti'
            );
            this.failedLogin = true;
            this.validEmail = false;
            this.isValidPass = false;
            if (err.error.data.errors === 'user not activated') {
              this.errorUser = 'Email anda belum diverifikasi';
              this.validEmail = false;
              this.failedLogin = false;
              this.isValidPass = true;
            }
            if (err.error.data.errors === 'email not registered') {
              this.errorUser = 'Email belum terdaftar';
              this.validEmail = false;
              this.failedLogin = false;
              this.isValidPass = true;
            }
          }
        );
    }
  }
  ngOnDestroy() {}
}
