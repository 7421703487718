import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KomerceDateRangePickerComponent } from './components/komerce-date-range-picker/komerce-date-range-picker.component';
import { KomerceInputComponent } from './components/komerce-input/komerce-input.component';
import { KomerceSearchbarComponent } from './components/komerce-searchbar/komerce-searchbar.component';
import { KomerceShimmerComponent } from './components/komerce-shimmer/komerce-shimmer.component';
import {IdrCurrencyPipe} from "./pipe/idr-currency.pipe";
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    KomerceDateRangePickerComponent,
    KomerceInputComponent,
    KomerceSearchbarComponent,
    KomerceShimmerComponent,

    //PIPE
    IdrCurrencyPipe
  ],
  imports: [
    CommonModule,
    FormsModule

  ],
  exports: [
    KomerceDateRangePickerComponent,
    KomerceInputComponent,
    KomerceSearchbarComponent,

    //PIPE
    IdrCurrencyPipe
  ]
})
export class LibsModule { }
