import { Component, TemplateRef, ViewEncapsulation, Input, EventEmitter, Output } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbOffcanvas, NgbDropdownModule, NgbTypeaheadModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil, Observable, OperatorFunction } from 'rxjs';
import { BankListAvailModel } from 'src/app/models/bank-list-avail.model';
import { BankService } from 'src/app/services/api/bank.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ToastService } from 'src/app/services/toast.service';
import { BankModel } from 'src/app/models/bank.model';
import { ModalPinComponent } from '../profile/components/modal-pin-verification/modal-pin-verification.component';
import { UserService } from 'src/app/services/api/user.service';
import { ResponseCustomType } from 'src/app/types/response/response.type';
import { ProfileResponseType } from 'src/app/types/response/profile-response.type';
import { BuatPinComponent } from 'src/app/pages/dashboard/components/modal-buat-pin/buat-pin.component';

@Component({
  selector: 'app-rekening',
  templateUrl: './rekening.component.html',
  styleUrls: ['./rekening.component.scss']
})
export class RekeningComponent {
  formGroup: FormGroup = new FormGroup({
    bank: new FormControl('', [Validators.required]),
    nama: new FormControl('', [Validators.required]),
    rekening: new FormControl('', [Validators.required]),
  });
  myGroup!: FormGroup;
  destroy$ = new Subject();
  bankNameList: BankListAvailModel[] = [];
  bankList: BankModel[] = [];
  isNotFound: boolean = false;
  rekening!: string;
  owner!: string;
  bank!: string;
  isDisable: boolean = true;
  isEmptyBank: boolean = false;
  isEmptyRek: boolean = false;
  isEmptyOwn: boolean = false;
  isDisSave: boolean = false;
  addDisable: boolean = false;
  profile!: ProfileResponseType

  @Input() paramsStoreBank: any = {
    bank_code: '',
    bank_name: '',
    bank_number: '',
    bank_owner: '',
  }
  @Input() paramsUpdateBank: any = {
    id: '',
    bank_code: '',
    bank_name: '',
    bank_number: '',
    bank_owner: '',
  }

  @Output() filterResult = new EventEmitter();

  constructor(
    private offcanvasService: NgbOffcanvas,
    private bankService: BankService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private userService: UserService,
    ) {}

  ngOnInit(): void {
    this.getBankName()
    this.getBankList()
    this.getProfile()
  }

  getProfile(){
    this.userService
    .profile()
    .pipe(takeUntil(this.destroy$))
    .subscribe((res: ResponseCustomType<ProfileResponseType>) => {
      this.profile = res.data

      if(res.data.pin_status === true) {
        this.modalService.open(ModalPinComponent, {
          backdrop: 'static',
          centered: true,
        });
      } else {
        this.modalService.open(BuatPinComponent, {
          backdrop: 'static',
          centered: true,
        });
      }
    })
  }

  get form() {
    return {
      formGroup: this.formGroup,
      bank: this.formGroup.get('bank'),
      rekening: this.formGroup.get('rekening'),
      nama: this.formGroup.get('nama'),
    };
  }

  onSearchBank(value: any) {
    this.paramsStoreBank.bank_name = value.bank_name
    this.paramsStoreBank.bank_code = value.bank_code
    this.filterResult.emit(this.paramsStoreBank)
  }
  onAddRek(value: any) {
    this.paramsStoreBank.bank_number = value
    this.filterResult.emit(this.paramsStoreBank)
  }
  onAddOwner(value: any) {
    this.paramsStoreBank.bank_owner = value
    this.filterResult.emit(this.paramsStoreBank)
  }

  onEditBankName(value: any, i: number) {
    const owner = this.bankList[i].bank_owner
    const number = this.bankList[i].bank_number

    this.paramsUpdateBank.bank_owner = owner
    this.paramsUpdateBank.bank_number = number

    this.paramsUpdateBank.bank_name = value.bank_name
    this.paramsUpdateBank.bank_code = value.bank_code
    
    this.filterResult.emit(this.paramsUpdateBank)

    if (value === '') {
      this.bankList[i].isEmptyBank = true
      this.isDisSave = true
    } else if ( value.bank_name === this.bankList[i].bank_name) {
      this.isDisSave = true
    } else {
      this.bankList[i].isEmptyBank = false
      this.isDisSave = false
    }
  }
  onEditBankNumber(value: any, i: number) {
    const bank = this.bankList[i].bank_name
    const code = this.bankList[i].bank_code

    this.paramsUpdateBank.bank_name = bank
    this.paramsUpdateBank.bank_code = code
    this.paramsUpdateBank.bank_number = value
    this.filterResult.emit(this.paramsUpdateBank)

    if (value === '') {
      this.bankList[i].isEmptyRek = true
      this.isDisSave = true
    } else if ( value === this.bankList[i].bank_number) {
      this.isDisSave = true
    } else {
      this.bankList[i].isEmptyRek = false
      this.isDisSave = false
    }
  }
  onEditBankOwner(value: any, i: number) {
    const bank = this.bankList[i].bank_name
    const code = this.bankList[i].bank_code

    this.paramsUpdateBank.bank_name = bank
    this.paramsUpdateBank.bank_code = code
    this.paramsUpdateBank.bank_owner = value
    this.filterResult.emit(this.paramsUpdateBank)

    if (value === '') {
      this.bankList[i].isEmptyOwn = true
      this.isDisSave = true
    } else if ( value === this.bankList[i].bank_owner) {
      this.isDisSave = true
    } else {
      this.bankList[i].isEmptyOwn = false
      this.isDisSave = false
    }
  }

  openAddRek(content: TemplateRef<any>) {
		this.offcanvasService.open(content, { position: 'end', backdrop: 'static'});
	}
  closeAddRek(content: TemplateRef<any>) {
		this.offcanvasService.dismiss(content);
    this.isEmptyBank = false
    this.isEmptyRek = false
    this.isEmptyOwn = false
    this.formGroup.get('nama')?.reset()
    this.formGroup.get('rekening')?.reset()
    this.formGroup.get('bank')?.reset()
	}

  getBankName(): void {
    this.bankService
    .bankListAvail()
    .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.bankNameList = res.data
          this.isNotFound = this.bankNameList.length === 0
        },
        (error) => {}
      );
  }

  formatter = (x: { bank_name: string }) =>
    x.bank_name;

  search: OperatorFunction<
    string,
    readonly { bank_name: string, bank_code: string }[]
  > = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      map((term) =>
        term === ''
          ? []
          : this.bankNameList
              .filter(
                (v: BankListAvailModel) =>
                  (v.bank_name)
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) > -1
              )
              .slice(0, 10)
      )
    );

  validateAdd(): void {
    if (
      this.paramsStoreBank.bank_name === '') {
      this.isEmptyBank = true
    } if (this.paramsStoreBank.bank_owner === '') {
      this.isEmptyOwn = true
    } if (this.paramsStoreBank.bank_number === '') {
      this.isEmptyRek = true
    } else {
      this.isEmptyBank = false
      this.isEmptyRek = false
      this.isEmptyOwn = false
      this.addRekening()
    }
  }

  addRekening(): void {
    this.bankService
    .bankStore(this.paramsStoreBank)
    .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.meta.code === 201) {
            this.toastService.show(null, 'Berhasil Menambahkan Rekening Bank', 'success', {
              classname: 'bg-success',
              delay: 5000,
            });
            this.offcanvasService.dismiss(this.offcanvasService);
            this.formGroup.get('nama')?.reset()
            this.formGroup.get('rekening')?.reset()
            this.formGroup.get('bank')?.reset()
            this.getBankList()
          }
        },
        (error) => {}
      );
  }

  async getBankList(): Promise<void> {
    this.bankService
    .bankList()
    .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.bankList = res.data
          if (this.bankList.length >= 6) {
            this.addDisable = true
          } else {
            this.addDisable = false
          }
          
          this.bankList.forEach(item => {
            item.isDisable = true;
            item.isEmptyBank = false;
            item.isEmptyRek = false;
            item.isEmptyOwn = false;
          });
        },
        (error) => {}
      );
  }

  batal(i: number) {
    this.bankList[i].isDisable = false

    this.getBankList()
  }

  edit(i: number) {
    this.bankList[i].isDisable = false
    this.onEditBankName(this.bankList[i].bank_name, i)
    this.onEditBankNumber(this.bankList[i].bank_number, i)
    this.onEditBankOwner(this.bankList[i].bank_owner, i)
  }
  simpan(i: number) {
    const name = this.bankList[i].bank_owner
    const id = this.bankList[i].id
    const number = this.bankList[i].bank_number
    const bank = this.bankList[i].bank_name
    const code = this.bankList[i].bank_code
    
    this.paramsUpdateBank.id = id

    if (this.paramsUpdateBank.bank_owner === '') {
      this.paramsUpdateBank.bank_owner = name
    } if (this.paramsUpdateBank.bank_number === '') {
      this.paramsUpdateBank.bank_number = number
    } if (this.paramsUpdateBank.bank_name === '') {
      this.paramsUpdateBank.bank_name = bank
      this.paramsUpdateBank.bank_code = code
      
    } if (this.paramsUpdateBank.bank_name !== '') {
      this.onEditBankName
      this.getBankList()
    }

    this.filterResult.emit(this.paramsUpdateBank)
    
    this.editBank()
  }
  editBank(): void {    
    this.bankService
    .bankUpdate(this.paramsUpdateBank)
    .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.meta.code === 200) {
            this.toastService.show(null, 'Berhasil Mengedit Rekening Bank', 'success', {
              classname: 'bg-success',
              delay: 5000,
            });
            this.getBankList()
          }
        },
        (error) => {}
      );
  }

  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }

}
