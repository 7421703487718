import {
    Component,
    OnDestroy,
    OnInit,
    EventEmitter,
    Input,
    NgModule,
    Output,
    LOCALE_ID,
    ViewChild,
    ElementRef,
  } from '@angular/core';
import { Router, RouterModule, Routes, ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FormGroup, FormsModule } from '@angular/forms';
import { BalanceService } from 'src/app/services/api/balance.service';
import { ToastService } from 'src/app/services/toast.service';
import * as moment from 'moment';
import 'moment/locale/id';
import {
  LocaleConfig,
  DaterangepickerDirective,
} from 'ngx-daterangepicker-material';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Dayjs } from 'dayjs';
import { offset } from '@popperjs/core';
import { HistoryBalanceModel } from 'src/app/models/history-balance.model';
import { BalanceResponseType } from 'src/app/types/response/balance-response.type';
import { ResponseCustomType } from 'src/app/types/response/response.type';
import { balanceModel } from 'src/app/models/balance.model';
import { ComissionService } from 'src/app/services/api/comission.service';

@Component({
    selector: 'app-detail-komisi',
    templateUrl: './detail-komisi.component.html',
    styleUrls: ['./detail-komisi.component.scss'],
    providers: [{ provide: LOCALE_ID, useValue: 'id' }]
  })

  export class DetailKomisiComponent {
    @Input() HistoryList: any[] = []
    @Input() filterHistory: any = {
        start_date: moment().format('YYYY-MM-DD') + ' ' + '00:00:00',
        end_date: moment().format('YYYY-MM-DD') + ' ' + '23:59:59',
        transaction_type: "withdrawal,order_commission",
        limit: 50,
        offset: 0,
        search: '',
      };
    keepCalendarOpeningWithRange: boolean;
    @Output() filterResult = new EventEmitter();
    destroy$ = new Subject();
    @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective!: DaterangepickerDirective;
    @ViewChild('checkBoxRef', { static: false }) checkBoxRef!: ElementRef;
    @Input() balance!: balanceModel;
    form!: FormGroup;
    uncheck = false;
    uncheckList!: boolean;

    selected: any = {
        startDate: moment(),
        endDate: moment(),
      };
    
      ranges: any = {
        'Hari ini': [moment(), moment()],
        'Kemarin': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        '7 hari terakhir': [moment().subtract(6, 'days'), moment()],
        '30 hari terakhir': [moment().subtract(29, 'days'), moment()],
        'Bulan ini': [moment().startOf('month'), moment().endOf('month')],
        'Bulan Lalu': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      };
    
      invalidDates: moment.Moment[] = [
        moment().add(2, 'days'),
        moment().add(3, 'days'),
        moment().add(5, 'days'),
      ];
    
      isInvalidDate = (m: moment.Moment) => {
        return this.invalidDates.some((d) => d.isSame(m, 'day'));
      };

    setlocale: LocaleConfig = {
        applyLabel: 'Simpan',
        cancelLabel: 'Batal',
        separator: ' - ',
        clearLabel: 'Batal',
        format: 'DD MMM YYYY',
        displayFormat: 'DD MMM YYYY',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
        ],
    };

    status: any = [
        {
          value: 'withdrawal',
          label: 'Penarikan Komisi',
          id: 'withdrawal',
          checked: false
        },
        {
          value: 'order_commission',
          label: 'Komisi Order',
          id: 'order_commission',
          checked: false
        },
      ]
    
      selectedStatuses: string[] = [];
      resi: string = '';

    constructor(
        private balanceService: BalanceService,
        private commisionService: ComissionService,
        private route: Router,
        private toastService: ToastService,
        public formatter: NgbDateParserFormatter,
        ) {
            this.keepCalendarOpeningWithRange = true;
      }
      ngOnInit(): void {
        this.getHistory()
        this.getBalance()
      }
      getBalance() {
        this.balanceService
          .balance()
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (res: ResponseCustomType<BalanceResponseType>) => {
              this.balance = res.data
            },
            (error) => {}
          );
      }
      back() {
        this.route.navigate(['pages/keuangan/saldo'])
      }

      onChangeSearch(value: any){
        this.filterHistory.search = value
        this.filterResult.emit(this.filterHistory)
        this.getHistory();
      }

      getStatus(event: any, list: any) {
        const inputElement = event.target as HTMLInputElement;
        const isChecked = inputElement.checked;
      
        if (isChecked && !this.selectedStatuses.includes(list.value)) {
          this.selectedStatuses.push(list.value);
        } else if (!isChecked && this.selectedStatuses.includes(list.value)) {
          this.selectedStatuses = this.selectedStatuses.filter(value => value !== list.value);
        }
      
        if (this.selectedStatuses.length > 0) {
          this.filterHistory.transaction_type = this.selectedStatuses.join(',');
        } else {
          this.filterHistory.transaction_type = "withdrawal,order_commission";
          this.selectedStatuses = [];
        }
      
        this.status = this.status.map((item: any) => {
          if (item.value === list.value) {
            return { ...item, checked: isChecked };
          }
          return item;
        });
      
        this.filterResult.emit(this.filterHistory);
        this.getHistory();
      }
      
      resetFilter() {
        this.filterHistory.start_date = moment().format('YYYY-MM-DD') + ' ' + '00:00:00';
        this.filterHistory.end_date = moment().format('YYYY-MM-DD') + ' ' + '23:59:59';
        this.filterHistory.transaction_type = "withdrawal,order_commission",
        this.selectedStatuses = [];
    
        this.filterResult.emit(this.filterHistory);
        this.getHistory()

        this.status.forEach((item: any) => {
          item.checked = false
        });
      }
      openDatepicker() {
        this.pickerDirective.open();
      }
      onChangeDate(e: any) {
        const endDate: Dayjs = e.endDate;
        const startDate: Dayjs = e.startDate;

        this.filterHistory.start_date = startDate.format('YYYY-MM-DD') + ' ' + '00:00:00';
    
        this.filterHistory.end_date = endDate.format('YYYY-MM-DD') + ' ' + '23:59:59';
    
        this.filterResult.emit(this.filterHistory);
        this.getHistory()
      }

      salinResi(): void {
        const noresi = this.resi;
        navigator.clipboard
          .writeText(noresi)
          .then(() => {
            this.toastService.show(null, 'Nomor Resi berhasil disalin', 'success', {
              classname: 'bg-success',
              delay: 3000,
            });
          })
          .catch((e) => console.log(e));
      }

      getHistory(): void {
        this.commisionService
          .commisionHistory(this.filterHistory)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (res) => {
              this.HistoryList = res.data
            },
            () => {}
          );
      }
      transactionType(v: string) {
        if (v === 'order_commission') {
          return 'Orderan Selesai'
        } else {
          return 'Penarikan Komisi'
        }
      }
      ngOnDestroy(): void {
        this.destroy$.unsubscribe()
      } 
  }