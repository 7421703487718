<div class="py-2">
  <nz-card class="rounded p-4">
    <div class="title py-2">Ticketing</div>
    <hr />
    <div
      nz-row
      [nzGutter]="{ xs: 8, sm: 12, md: 24, lg: 32 }"
      class="d-flex px-2 flex-wrap"
    >
      <div class="col d-flex col-md-3">
        <nz-card class="bg-orange text-white rounded" style="height: 140px">
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column gap-2 justify-content-start p-2">
              <span class="d-flex text-bold">
                Menunggu Tindak Lanjut Seller
              </span>
              <div class="d-flex gap-2 align-items-center">
                <img
                  width="40px"
                  height="40px"
                  src="https://storage.googleapis.com/komerce/assets/svg/new-icon-perlu-tindak-lanjut.svg"
                  alt=""
                />
                <h3 class="text-white">{{ countTicket.waiting_seller }}</h3>
              </div>
            </div>
            <div class="bg-orange-s d-flex justify-content-end"></div>
          </div>
        </nz-card>
      </div>
      <div class="col d-flex col-md-3">
        <nz-card class="bg-pink text-white rounded" style="height: 140px">
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column justify-content-start p-2">
              <div class="d-flex flex-column gap-2">
                <span class="d-flex align-items-start text-bold">
                  Belum Diproses Ekspedisi
                </span>
                <div class="d-flex align-items-end">
                  <div class="d-flex gap-2 align-items-center">
                    <img
                      width="40px"
                      height="40px"
                      src="https://storage.googleapis.com/komerce/assets/svg/new-icon-belum-diproses.svg"
                      alt=""
                    />
                    <h3 class="text-white">
                      {{ countTicket.pending_shipment }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-pink-s d-flex justify-content-end"></div>
          </div>
        </nz-card>
      </div>
      <div class="col d-flex col-md-3">
        <nz-card class="bg-blue text-white rounded" style="height: 140px">
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column justify-content-start p-2">
              <div class="d-flex flex-column gap-2">
                <span class="d-flex align-items-start text-bold">
                  Sedang Diproses Ekspedisi
                </span>
                <div class="d-flex align-items-end">
                  <div class="d-flex gap-2 align-items-center">
                    <img
                      width="40px"
                      height="40px"
                      src="https://storage.googleapis.com/komerce/assets/svg/new-icon-sedang-diproses.svg"
                      alt=""
                    />
                    <h3 class="text-white">{{ countTicket.in_transit }}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-blue-s d-flex justify-content-end"></div>
          </div>
        </nz-card>
      </div>
      <div class="col d-flex col-md-3">
        <nz-card class="bg-green text-white rounded" style="height: 140px">
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column gap-2 justify-content-start p-2">
              <span class="d-flex text-bold align-items-center gap-1">
                Selesai
                <div class="line"></div>
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/thropy-selesai.svg"
                  alt=""
                />
                <span class="text-small"
                  >{{ countTicket.successfully_received }}<br />Berhasil
                  Diterima</span
                >
              </span>
              <div class="d-flex gap-2 align-items-center">
                <img
                  width="40px"
                  height="40px"
                  src="https://storage.googleapis.com/komerce/assets/svg/new-icon-selesai.svg"
                  alt=""
                />
                <h3 class="text-white">{{ countTicket.completed }}</h3>
              </div>
            </div>
            <div class="bg-green-s d-flex justify-content-end"></div>
          </div>
        </nz-card>
      </div>
    </div>
    <hr />
    <app-table-ticket></app-table-ticket>
  </nz-card>
</div>
