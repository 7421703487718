<div class="login-container">
  <img
    src="https://storage.googleapis.com/komerce/assets/illustration/bg-yellow-ro.png"
    class="background"
  />
  <div class="content-login">
    <div
      class="w-100 h-100 gap-3 d-flex align-items-center justify-content-center"
    >
      <div class="d-flex flex-column gap-3">
        <div class="logo-login d-flex justify-content-center">
          <img
            style="max-height: 50px"
            src="https://storage.googleapis.com/komerce/core/RO%20by%20Komerce.svg"
          />
        </div>
        <div>
          <form [formGroup]="formGroup" *ngIf="!success">
            <div
              class="px-md-5 px-3 py-3 m-3 card shadow d-flex flex-column gap-3"
            >
              <div class="text-center desc">
                <div class="title mt-3 mb-1">
                  <strong>Lupa Password</strong>
                </div>
                <div>
                  Masukkan Email anda, tautan untuk mengatur ulang password
                  <br />
                  akan dikirm melalui email
                </div>
              </div>
              <div class="form-group mt-1">
                <label for="username">Email</label>
                <input
                  nz-input
                  nzSize="large"
                  type="email"
                  (keypress)="setRegex($event)"
                  formControlName="email"
                  class="form-control"
                  id="username"
                  aria-describedby="username"
                  placeholder="username@domain.com"
                  (keydown.enter)="$event.preventDefault()"
                  (input)="validateEmail()"
                  [nzStatus]="validEmail ? '' : 'error'"
                />
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-danger">
                    <img
                      *ngIf="errorUser !== ''"
                      src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                      alt="warning"
                      style="max-width: 10px; max-height: 10px"
                    />
                    {{ errorUser }}</span
                  >
                  <span
                    *ngIf="errorUser === 'Email anda belum diverifikasi'"
                    class="d-flex justify-content-end align-items-end text-blue"
                    style="cursor: pointer"
                    (click)="resendVerif($event)"
                    >Kirim Ulang</span
                  >
                </div>
                <div class="mb-2 mt-3">
                  <button
                    [disabled]="formGroup.invalid"
                    (click)="getCaptchaToken($event)"
                    class="btn w-100"
                    [ngClass]="
                      formGroup.invalid ? 'btn-secondary' : 'btn-ro-primary'
                    "
                  >
                    <div
                      class="d-flex flex-row gap-2 align-items-center justify-content-center"
                    >
                      <div style="color: white">Kirim</div>
                      <div
                        *ngIf="isLoading"
                        class="spinner-border spinner text-light"
                        role="status"
                      >
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  </button>
                </div>
                <div class="text-center mb-2">
                  Sudah ingat password anda ?
                  <a class="text-yellow" href="/login">Masuk</a>
                </div>
              </div>
            </div>
          </form>
          <div
            *ngIf="success"
            class="card shadow p-5 d-flex align-items-center justify-content-center gap-3"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/sms-success.svg"
              alt="succes"
              style="max-width: 50px"
            />
            <h5><strong>Email Berhasil Terkirim</strong></h5>
            <span>Silahkan cek email anda untuk melakukan reset password</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
