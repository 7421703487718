import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ProfileService } from 'src/app/services/profile.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Output() isActive = new EventEmitter();
  @Input() active: boolean = false;

  public isOpen = true;
  activeButton: string = 'dashboard';
  isRajaOngkir: boolean = false;
  profileData!: any;
  private profileSubscription!: Subscription;

  icons = {
    settings: {
      active:
        'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/setting-2.svg',
      default:
        'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/setting-2.svg',
    },
    arrow: {
      up: 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-up-2.svg',
      down: 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg',
    },
  };

  menuItems = [
    {
      name: 'dashboard',
      label: 'Dashboard',
      link: '/pages/dashboard',
      icon: {
        active:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/home-hashtag.svg',
        default:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/home-hashtag.svg',
      },
    },
    {
      name: 'Data Order',
      label: 'Data Order',
      link: '/pages/data-order',
      icon: {
        active:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/bag.svg',
        default:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/bag.svg',
      },
    },
    {
      name: 'Keuangan',
      label: 'Keuangan',
      link: '/pages/keuangan/saldo',
      icon: {
        active:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/empty-wallet.svg',
        default:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/empty-wallet.svg',
      },
    },
    {
      name: 'Billing',
      label: 'Billing',
      link: '/pages/billings',
      icon: {
        active:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/bill.svg',
        default:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/bill.svg',
      },
    },
    {
      name: 'Kendala',
      label: 'Kendala',
      link: '/pages/ticketing',
      icon: {
        active:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/message-remove.svg',
        default:
          'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/message-remove.svg',
      },
    },
  ];

  subMenuItems = [
    {
      name: 'Profile',
      label: 'Profile',
      link: '/pages/pengaturan/profile',
    },
    {
      name: 'PIN',
      label: 'PIN',
      link: '/pages/pengaturan/pin',
    },
    {
      name: 'Rekening Bank',
      label: 'Rekening Bank',
      link: '/pages/pengaturan/rekening',
    },
  ];

  constructor(
    private elementRef: ElementRef,
    private route: Router,
    private profileService: ProfileService
  ) {
    route.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const url = val.url || '';
        this.setActiveButtonFromUrl(url);
      }
    });
  }

  inside = false;

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.isActive.emit(false);
    }
  }

  ngOnInit() {
    this.profileSubscription = this.profileService.profileData$.subscribe(
      (data) => {
        if (data) {
          this.profileData = data;
        }
      }
    );
  }

  setActiveButtonFromUrl(url: string) {
    this.menuItems.forEach((item) => {
      if (url.startsWith(item.link)) {
        this.activeButton = item.name;
        this.isOpen =
          item.name !== 'Profile' &&
          item.name !== 'PIN' &&
          item.name !== 'Rekening Bank';
      }
    });

    this.subMenuItems.forEach((subItem) => {
      if (url.startsWith(subItem.link)) {
        this.activeButton = subItem.name;
        this.isOpen = false;
      }
    });
  }

  toggleSettings() {
    this.isOpen = !this.isOpen;
  }

  setNavActive(data: any): void {
    this.isActive.emit(false);
  }

  ngOnDestroy(): void {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
  }
}
