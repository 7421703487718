import {
  Component,
  OnDestroy,
  OnInit,
  EventEmitter,
  Input,
  NgModule,
  Output,
  LOCALE_ID,
  ViewChild,
} from '@angular/core';
import { BalanceService } from '../../services/api/balance.service';
import { BaseService } from 'src/app/services/base.service';
import { Subject, takeUntil } from 'rxjs';
import {
  NgbModal,
  NgbDate,
  NgbCalendar,
  NgbDatepickerModule,
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { ResponseCustomType } from '../../types/response/response.type';
import { BalanceResponseType } from '../../types/response/balance-response.type';
import { PossibleIncomeResponseType } from '../../types/response/possible-income-response.type';
import { OrderService } from '../../services/api/order.service';
import { AccumulationDataOrderResponseType } from '../../types/response/accumulation-data-order-response.type';
import { AccumulationDataOrderRequsetType } from '../../types/request/accumulation-data-order-requset.type';
import { ModalTarikSaldoComponent } from './components/modal-tarik-saldo/modal-tarik-saldo.component';
import { GET_BALANCE, REFRESH_TOKEN } from 'src/app/constants/endpoint.const';
import * as moment from 'moment';
import 'moment/locale/id';
import {
  LocaleConfig,
  DaterangepickerDirective,
} from 'ngx-daterangepicker-material';
import { Dayjs } from 'dayjs';
import localeId from '@angular/common/locales/id';
import id from '@angular/common/locales/id';
import { registerLocaleData } from '@angular/common';
import { MaintenanceComponent } from 'src/app/modules/shared/components/maintenance/maintenance.component';
import { ProfileResponseType } from 'src/app/types/response/profile-response.type';
import { UserService } from 'src/app/services/api/user.service';
import { BuatRekeningComponent } from './components/modal-buat-rekening/buat-rek.component';
import { BankService } from 'src/app/services/api/bank.service';
import { BankModel } from 'src/app/models/bank.model';
import { BuatPinComponent } from './components/modal-buat-pin/buat-pin.component';
import { ModalTarikKomisiComponent } from './components/modal-tarik-komisi/modal-tarik-kom.component';
import { wdListModel } from 'src/app/models/withdrawl-list.model';
import { BlokReqWDComponent } from 'src/app/modules/shared/components/modal-blocked-request-wd/blocked-req-wd.component';
registerLocaleData(localeId);

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: 'id' }],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild(DaterangepickerDirective, { static: false })
  datePicker!: DaterangepickerDirective;
  destroy$ = new Subject();
  profile!: ProfileResponseType;
  bankList: BankModel[] = [];
  @Input() wdList: wdListModel[] = [];
  balance: BalanceResponseType = {
    balance: 0,
    commission: 0,
  };

  possibleIncome: PossibleIncomeResponseType = {
    possible_income: 0,
  };

  accumulationOrder: any;

  startDate = '';
  endDate = '';

  selected: any = {
    startDate: moment().startOf('month').locale('id'),
    endDate: moment().endOf('month').locale('id'),
  };

  ranges: any = {
    'Hari ini': [moment(), moment()],
    '7 hari terakhir': [moment().subtract(6, 'days'), moment()],
    '1 Bulan terakhir': [moment().subtract(29, 'days'), moment()],
    'Bulan ini': [moment().startOf('month'), moment().endOf('month')],
  };

  invalidDates: moment.Moment[] = [
    moment().add(2, 'days'),
    moment().add(3, 'days'),
    moment().add(5, 'days'),
  ];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  @Input() filter: any = {
    start_date: moment().startOf('month').locale('id').format('YYYY-MM-DD'),
    end_date: moment().endOf('month').locale('id').format('YYYY-MM-DD'),
  };

  @Input() filterList: any = {
    start_date: '2019-01-01',
    end_date: moment().format('YYYY-MM-DD'),
    type: 'balance',
  };

  @Output() filterResult = new EventEmitter();
  setlocale: LocaleConfig = {
    applyLabel: 'Simpan',
    cancelLabel: 'Kembali',
    separator: ' - ',
    clearLabel: 'Batal',
    format: 'DD MMM YYYY',
    displayFormat: 'DD MMM YYYY',
    daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
    monthNames: [
      'Januari',
      'Februari',
      'Maret',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Agustus',
      'September',
      'Oktober',
      'November',
      'Desember',
    ],
  };
  keepCalendarOpeningWithRange: boolean;
  pinStatus!: boolean;
  modalBlock!: boolean;

  constructor(
    private balanceService: BalanceService,
    private orderService: OrderService,
    private modalService: NgbModal,
    public formatter: NgbDateParserFormatter,
    public baseService: BaseService,
    private userService: UserService,
    private bankService: BankService
  ) {
    this.keepCalendarOpeningWithRange = true;
  }
  ngOnInit(): void {
    moment.updateLocale('id', id);
    this.getBalance();
    this.getPossibleIncome();
    this.getAccumulationOrder();
    this.getProfile();
    this.getBankList();
    this.getListWD('balance');
  }
  openDatepicker() {
    this.datePicker.open();
  }

  onChangeDate(e: any) {
    const endDate: Dayjs = e.endDate;
    const startDate: Dayjs = e.startDate;

    this.filter.start_date = startDate.format('YYYY-MM-DD');

    this.filter.end_date = endDate.format('YYYY-MM-DD');

    this.filterResult.emit(this.filter);
    this.getAccumulationOrder();
  }
  getPossibleIncome(): void {
    this.balanceService
      .possibleIncome()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: ResponseCustomType<PossibleIncomeResponseType>) => {
          this.possibleIncome = res.data;
        },
        (error) => {}
      );
  }
  getBalance(): void {
    this.balanceService
      .balance()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: ResponseCustomType<BalanceResponseType>) => {
          this.balance = res.data;
        },
        (error) => {}
      );
  }

  handleTarikSaldo(e: any, type: string): void {
    const filterList: any = {
      start_date: '2019-01-01',
      end_date: moment().format('YYYY-MM-DD'),
      type: 'balance',
    };

    this.balanceService
      .withdrawlList(filterList)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          const dataList = res.data;

          let modalBlock = false;

          if (dataList !== null) {
            for (let i = 0; i < dataList.length; i++) {
              if (
                (dataList[i].type === 'balance' &&
                  dataList[i].status === 'request') ||
                dataList[i].status === 'review'
              ) {
                modalBlock = true;
                break;
              }
            }

            if (modalBlock === true) {
              this.modalService.open(BlokReqWDComponent, {
                backdrop: 'static',
                centered: true,
              });
            } else {
              this.modalService.open(ModalTarikSaldoComponent, {
                backdrop: 'static',
                centered: true,
              });
            }
          } else {
            this.modalService.open(ModalTarikSaldoComponent, {
              backdrop: 'static',
              centered: true,
            });
          }
        },
        (error) => {}
      );
  }
  handleTarikKomisi(e: any, type: string): void {
    const filterList: any = {
      start_date: '2019-01-01',
      end_date: moment().format('YYYY-MM-DD'),
      type: 'commission',
    };

    this.balanceService
      .withdrawlList(filterList)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          const dataList = res.data;

          let modalBlock = false;

          if (dataList !== null) {
            for (let i = 0; i < dataList.length; i++) {
              if (
                (dataList[i].type === 'commission' &&
                  dataList[i].status === 'request') ||
                dataList[i].status === 'review'
              ) {
                modalBlock = true;
                break;
              }
            }

            if (modalBlock === true) {
              this.modalService.open(BlokReqWDComponent, {
                backdrop: 'static',
                centered: true,
              });
            } else {
              this.modalService.open(ModalTarikKomisiComponent, {
                backdrop: 'static',
                centered: true,
              });
            }
          } else {
            this.modalService.open(ModalTarikKomisiComponent, {
              backdrop: 'static',
              centered: true,
            });
          }
        },
        (error) => {}
      );
  }
  handleNoRek(e: any): void {
    this.modalService.open(BuatRekeningComponent, {
      backdrop: 'static',
      centered: true,
    });
  }
  handleNoPIN(e: any): void {
    this.modalService.open(BuatPinComponent, {
      backdrop: 'static',
      centered: true,
    });
  }

  maintenance(e: any): void {
    this.modalService.open(MaintenanceComponent, {
      backdrop: 'static',
      centered: true,
    });
  }
  getAccumulationOrder(): void {
    this.orderService
      .accumulationDataOrder(this.filter)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: ResponseCustomType<AccumulationDataOrderResponseType>) => {
          this.accumulationOrder = res.data;
        },
        (error) => {}
      );
  }

  getProfile() {
    this.userService
      .profile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: ResponseCustomType<ProfileResponseType>) => {
        this.profile = res.data;
        this.pinStatus = res.data.pin_status;
      });
  }

  getBankList(): void {
    this.bankService
      .bankList()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.bankList = res.data;
        },
        (error) => {}
      );
  }

  getListWD(type: string) {
    this.balanceService
      .withdrawlList(this.filterList)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.wdList = res.data;
        },
        (error) => {}
      );
  }
  ngOnDestroy(): void {
    this.destroy$.unsubscribe();
  }
}
