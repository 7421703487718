import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/api/user.service';
import { BaseService } from '../../services/base.service';
import {
  FORGOT_PASSWORD,
  LOGIN,
  RECAPTCHA_TOKEN,
  RESEND_VERIFY,
} from 'src/app/constants/endpoint.const';
import { takeUntil, Subject } from 'rxjs';
import { LocalStorageService } from '../../services/local-storage.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ModalRegisterSuccessComponent } from '../register/modal-register-success/modal-register-success.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  formGroup: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  isLoading: boolean = false;
  destroy$ = new Subject();
  errorUser = '';
  validEmail: boolean = true;
  success: boolean = false;

  constructor(
    private baseService: BaseService,
    private renderer: Renderer2,
    private router: Router,
    private message: NzMessageService,
    private BaseService: BaseService,
    private modalService: NzModalService
  ) {}
  ngOnInit(): void {
    this.addScriptToHead(
      `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_TOKEN}`
    );
  }

  addScriptToHead(src: string): void {
    const script = this.renderer.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    script.async = true;
    this.renderer.appendChild(document.head, script);
  }

  disableEnterKey(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  validateEmail() {
    const value = this.formGroup.get('email')?.value || '';
    const regex = /^[a-zA-Z0-9+@._-]+$/g;

    if (value === '') {
      this.validEmail = false;
      this.errorUser = 'Email harus diisi';
    } else {
      if (value.length < 10) {
        this.errorUser = 'Minimal 10 karakter';
        this.validEmail = false;
      } else if (value.length > 50) {
        this.errorUser = 'Maksimal 50 karakter';
        this.validEmail = false;
      } else if (!regex.test(value)) {
        this.validEmail = false;
        this.errorUser = 'Email harus berupa alamat surel yang benar';
      } else {
        const atIndex = value.indexOf('@');
        const lastDotIndex = value.lastIndexOf('.');
        const dotBeforeAt = value.substring(0, atIndex).includes('.');
        const dotAfterAt = value.substring(atIndex + 1).includes('.');

        if (
          atIndex === -1 ||
          lastDotIndex === -1 ||
          lastDotIndex === value.length - 1 ||
          value[atIndex + 1] === '.' ||
          !dotAfterAt
        ) {
          this.errorUser = 'Email harus berupa alamat surel yang benar';
          this.validEmail = false;
        } else {
          this.validEmail = true;
          this.errorUser = '';
        }
      }
    }
  }

  setRegex(event: any) {
    const regex = /^[a-zA-Z0-9@._-]+$/g;
    return regex.test(event.key);
  }

  get form() {
    return {
      formGroup: this.formGroup,
      email: this.formGroup.get('email'),
    };
  }

  async getCaptchaToken(event: Event) {
    event.preventDefault();

    grecaptcha.enterprise.ready(async () => {
      try {
        const token = await grecaptcha.enterprise.execute(
          `${RECAPTCHA_TOKEN}`,
          { action: 'FORGOT_PASSWORD' }
        );
        this.handleForgotPass(token);
      } catch (error) {
        console.error('Error executing reCAPTCHA:', error);
        this.message.create('error', 'Error executing reCAPTCHA');
      }
    });
  }

  handleForgotPass(e: string) {
    const params = {
      email: this.formGroup.get('email')?.value,
      recaptcha_token: e,
    };
    this.baseService
      .postData(FORGOT_PASSWORD, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.success = true;
        },
        (err) => {
          this.isLoading = false;
          this.message.create(
            'error',
            'Reset Password Gagal, Silakan Ulangi Kembali'
          );
          if (err.error.data.errors === 'user not activated') {
            this.errorUser = 'Email anda belum diverifikasi';
            this.validEmail = false;
          } else if (err.error.data.errors === 'record not found') {
            this.errorUser = 'Email belum terdaftar';
            this.validEmail = false;
          }
        }
      );
  }

  resendVerif(event: Event) {
    event.preventDefault();

    grecaptcha.enterprise.ready(async () => {
      try {
        const token = await grecaptcha.enterprise.execute(
          `${RECAPTCHA_TOKEN}`,
          { action: 'RESEND_ACTIVATION' }
        );

        const params = {
          email: this.formGroup.get('email')?.value,
          recaptcha_token: token,
        };

        this.BaseService.postData(RESEND_VERIFY, params)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (response) => {
              this.modalService.create({
                nzContent: ModalRegisterSuccessComponent,
                nzFooter: null,
              });
            },
            (err) => {
              this.message.create(
                'error',
                'Terjadi kesalahan. Silahkan coba lagi nanti'
              );
            }
          );
      } catch (error) {
        console.error('Error executing reCAPTCHA:', error);
        this.message.create('error', 'Error executing reCAPTCHA');
      }
    });
  }

  ngOnDestroy() {}
}
